import React from "react";
import axios from 'axios';
import { Box, Button, Checkbox, Grid } from "@mui/material";
import SkeletonComponent from "../utils/Skeleton.tsx";
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { ThemeProvider } from '@mui/material';
import CustomSnackbar from '../utils/SnackBarCustomComponent.tsx';
import FullscreenSpinner from "./FullscreenSpinner.tsx";
import { darkTheme } from '../utils/graphUtils.tsx';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import SwitchJobStatusDialogComponent from "./SwitchJobStatusDialogComponent.tsx";
import { CheckBox } from "@mui/icons-material";

interface TableRow {
    job_id: string,
    job_name: string,
    status: boolean,
    hasOwnership: boolean
}

export const JobManagementComponent = () => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [rows, setRows] = React.useState<TableRow[]>([]);
    const [isError, setIsError] = React.useState({label: '', message: '', correct: false});
    const [dialogData, setDialogData] = React.useState({open: false, name:'', action: ''});
    const [openSnack, setOpenSnack] = React.useState(false);

    const getUserData = () => {
        setIsLoading(true)
        return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_BACKEND_URL + 'jobs/get_all_jobs_status', {} )
            .then((response) => {
            resolve({ data: response.data });
            })
            .catch((error) => {
                setIsError({label: 'error', message: 'Errore durante l\'acuisizione dei dati', correct: false})
            });
        });
    };

    const handlerChangeStatus = (job_name, status) => {
        return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_BACKEND_URL + 'jobs/'+status, {"job_name":job_name})
            .then((response) => {
                callMutationUserData()
                setIsError({label: 'status', message: 'Stato del Job aggiornato con successo', correct: true})
            })
            .catch((error) => {
                setIsError({label: 'error', message: 'Errore durante la modifica dello status del job', correct: false})
            });
        });
    };

    function callMutationUserData() {
        getUserData()
        .then((response: any) => {
            let jobData: TableRow[] = response.data.data;
            if (jobData.length === 0) throw Error
            jobData = jobData.map(el => {return {...el, id:el.job_id}})
            setIsLoading(false)
            setRows(jobData);
        })
        .catch((error) => {
            setIsLoading(false); setIsError({label: 'error', message: 'Errore durante l\'acuisizione dei dati', correct: false})
        });
    }

    React.useEffect(() => {
        callMutationUserData()
    }, [])

    const handleOpenPopup = (job_name: string, action: string) => {
        setDialogData({open:true, name:job_name, action: action});
    }

    const handleClosePopup = (confirmed, name, action) => {
        setDialogData({open:false, name:'', action: ''});
        if(confirmed && action !== ''){
            handlerChangeStatus(name, action)          
        }
    };

    const handleRefresh = () => {
        callMutationUserData()
    };

    const columns: GridColDef<(typeof rows)[number]>[] = [
        { field: 'job_name', headerName: 'Nome', flex: 0.1, resizable: false, editable: false, sortable: true },
        { field: 'status', headerName: 'Status', flex: 0.1, resizable: false, editable: false, sortable: true, 
            renderCell: (params) => (
            <Grid container spacing={2} direction="row" sx={{ color: params.value ? 'green' : 'red', justifyContent: "center", alignItems: "center" }}>
                <Grid item sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <PowerSettingsNewIcon />
                </Grid>
                <Grid item sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    {params.value ? <span>ATTIVO</span> : <span>NON ATTIVO</span>}
                </Grid>
            </Grid>
        )},
        { field: 'hasOwnership', headerName: 'Azione', flex: 0.1, resizable: false, editable: false, sortable: true,
            renderCell: (params) => (
                (params.value
                ?   (!params.row.status
                        ?<div style={{ textAlign: "center" }}><Button onClick={(e) => handleOpenPopup(params.row.job_name, 'restart')}>START</Button></div>
                        :<div style={{ textAlign: "center" }}><Button onClick={(e) => handleOpenPopup(params.row.job_name, 'stop')}>STOP</Button><Button onClick={(e) => handleOpenPopup(params.row.job_name, 'restart')}>RESTART</Button></div>
                    )
                :(!params.row.status && <div style={{ textAlign: "center" }}><span>*Contattare la struttura IT</span></div>)
                )
        )},
        { field: 'ownership', headerName: 'Owneship', width: 150, resizable: false, editable: false, sortable: false, 
            renderCell: (params) => (
                <Grid container direction="row" sx={{ height: '100%', justifyContent: "center",  alignItems: "center" }} >
                    <img src={`${process.env.PUBLIC_URL}/images/RTL_logo.svg`} style={{ height: '80%', width:'auto' }}/>
                </Grid>
        )},
        { field: 'inDataPlatform', headerName: 'In Data Platform', width: 150, resizable: false, editable: false, sortable: false, 
            renderCell: (params) => (
                <Grid container direction="row" sx={{ height: '100%', justifyContent: "center",  alignItems: "center" }} >
                    <Checkbox disabled={false} checked={params.row.hasOwnership} />
                </Grid>
        )},
    ];

    return (
        <ThemeProvider theme={darkTheme}>
        <Grid container
            direction="row"
            rowGap={2}
            sx={{
            justifyContent: "center",
            alignItems: "stretch"
            }}>

            <Grid item
            container
            direction="row"
            sx={{
                justifyContent: "center",
                alignItems: "stretch",
                height: "stretch"
            }}
            >
            {(rows && rows.length > 0)
                ? <Box sx={{ minHeight: '600px', maxHeight: '700px', width: '100%' }} >
                    <DataGrid
                    rows={rows}
                    columns={columns}
                    initialState={{
                        sorting: {
                            sortModel: [
                            {
                                field: 'hasOwnership',
                                sort: 'desc',
                            },
                            ],
                        },
                        pagination: {
                            paginationModel: {
                                pageSize: 20,
                            },
                        },
                    }}
                    pageSizeOptions={[20, 50]}              
                    disableAutosize
                    disableRowSelectionOnClick
                    />
                </Box>
                : <SkeletonComponent height='700px' />
            }
            </Grid>

            <Grid item container direction="row" sx={{justifyContent: "center", alignItems: "stretch", height: "stretch"}} gap={2} >
            <button className={"button-query"} onClick={handleRefresh}>Aggiorna</button>
            </Grid>

            <FullscreenSpinner isLoading={isLoading} />
            <CustomSnackbar open={openSnack} setOpen={setOpenSnack} message={isError.message} correct={isError.correct}/>
            <SwitchJobStatusDialogComponent dialogData={dialogData} handleClose={handleClosePopup} />
        </Grid>
        </ThemeProvider>
    )
}