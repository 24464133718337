export const GraphData = {
    'overview': [
        {title:'HIGHLIGHTS AUDIENCE', payload:{start_date: '', end_date: '', end_point: 'SCORECARDS', chart_type: 'SCORECARDS'}},
        {title:'HIGHLIGHTS SOCIAL', filtersList:['social_pages_page_name'], payload:{start_date: '', end_date: '', page_name:[], end_point: 'social_scorecards', chart_type: 'social_scorecards'}},
        
        {title:'AUDIENCE', payload:{start_date: '', end_date: '', end_point: 'AUDIENCE', chart_type: 'AUDIENCE'}},
        
        {title:'CANALI | PROGRAMMI', payload:{start_date: '', end_date: '', end_point: 'CONTENUTI_LIVE', chart_type: 'CONTENUTI_LIVE'}},
        {title:'CANALE | SERIE | PROGRAMMA', payload: {start_date:"", end_date:"", group_by:["channel","series","program"], channel:[], series:[], program: [], end_point: 'contenuti_on_demand', chart_type:"CONCAT_BAR_CHART"}},
        
        {title:'SOCIAL REACH', filtersList:['social_pages_page_name'], payload: {start_date:"",end_date:"", group_by: ["page_name"], page_name:[], metric_name:["reach","page_impressions_unique"], end_point: 'social_pages', chart_type: "PIE_CHART"}},
        {title:'SOCIAL IMPRESSIONS', filtersList:['social_pages_page_name'], payload: {start_date:"",end_date:"", group_by: ["page_name"], page_name:[], metric_name:["impressions","page_impressions"], end_point: 'social_pages', chart_type: "PIE_CHART"}},
        {title:'SOCIAL NUM. CONTENUTI PUBBLICATI', filtersList:['social_pages_page_name'], payload: {start_date:"",end_date:"", group_by: ["page_name"], page_name:[], metric_name:["impressions","page_impressions"], end_point: 'social_pages', chart_type: "PIE_CHART"}},

        {title:'PIATTAFORMA', payload: { start_date:"", end_date:"", group_by:["macro_category"], macro_category:[], platform:[], end_point: 'platforms', chart_type:"PIE_CHART"}},

        {title:'UTENTI UNICI', payload:{ start_date: '', end_date: '', end_point: 'TER1_1', chart_type: 'TER1_1' }},
        {title:'FREQUENZA', payload:{ start_date: '', end_date: '', end_point: 'TER1_2', chart_type: 'TER1_2' }}, 
        {title:'CONTATTI EQUIVALENTI', payload:{ start_date: '', end_date: '', end_point: 'TER1_3', chart_type: 'TER1_3' }},
        {title:'DETTAGLIO', payload:{ end_point: 'TER2', chart_type: 'TER2'}}
    ], 
    'audience': [
        {title:'CANALI', payload:{start_date: '', end_date: '', group_by: ["name"], name:[], macro_category:[], type: "LIVE", chart_type: "PIE_CHART"}},
        {title:'TOUCHPOINTS VS. UTENTI UNICI', payload:{start_date: '', end_date: '', group_by: ["day_param"],  name:[], macro_category:[], chart_type: "MIXER_BAR_LINE_CHART"}},
        {title:'DISTRIBUZIONE CUMULATIVA TOUCHPOINTS PER FASCIA ORARIA', payload:{start_date: '', end_date: '', timezone:'Ora italiana', group_by: ["hour_trunc_param"],  name:[], macro_category:[], chart_type: "MIXER_BAR_LINE_CHART"}},
        {title:'PIATTAFORMA', payload:{start_date: '', end_date: '', group_by: ["macro_category"],  name:[], macro_category:[], chart_type: "PIE_CHART"}}
    ],
    'real_time': [
        {title:'ON AIR - DIGITAL', filtersList:['contenuti_live_hours', 'timezone'], payload:{start_date:"", end_date:"",group_by:["hour_param","name"], live_content:true, chart_type:"STACKED_BAR_CHART"}},
    ],
    'contenuti_live': [
        {title:'AUDIENCE CANALI LIVE', payload:{start_date:"", end_date:"",group_by:["name"], name:[], program_title:[], chart_type:"PIE_CHART"}},
        {title:'PROGRAMMI', payload: {start_date:"", end_date:"", group_by:["program_title"], name:[], program_title:[], chart_type:"SINGLE_BAR_CHART"}},
        {title:'CANALI | PROGRAMMI', payload: {start_date:"", end_date:"", group_by:["name","program_title"], name:[], program_title:[], chart_type:"CONCAT_BAR_CHART"}},
        {title:'DISTRIBUZIONE GIORNALIERA', payload: {start_date:"", end_date:"",group_by:["day_param","name"], name:[], program_title:[], chart_type:"STACKED_BAR_CHART"}},
        {title:'SPEAKERS', payload: {start_date:"", end_date:"", group_by:["speaker_name"], name:[], program_title:[], chart_type:"SINGLE_BAR_CHART"}}
    ], 
    'contenuti_on_demand': [
        {title:'CANALE', payload: {start_date:"",end_date:"", group_by:["channel"], channel:[], series:[], program: [], chart_type:"PIE_CHART"}},
        {title:'SERIE', payload: {start_date:"",end_date:"", group_by:["series"], channel:[], series:[], program: [], chart_type:"PIE_CHART"}},
        {title:'CANALE | SERIE | PROGRAMMA', payload: {start_date:"", end_date:"", group_by:["channel","series","program"], channel:[], series:[], program: [], chart_type:"CONCAT_BAR_CHART"}},
        {title:'PROGRAMMA | TITOLO', payload: {start_date:"", end_date:"", group_by:["program","name"], channel:[], series:[], program: [],  chart_type:"CONCAT_BAR_CHART"}},
        {title:'PROGRAMMA', payload: {start_date:"",end_date:"",group_by:["program"], channel:[], series:[], program: [], chart_type:"SINGLE_BAR_CHART"}},
        {title:'CONTENUTO', payload: {start_date:"",end_date:"",group_by:["content"], channel:[], series:[], program: [], chart_type:"SINGLE_BAR_CHART"}},
    ],
    'music': [
        {title:'TOP CANZONI', payload: {start_date:"",end_date:"",group_by:["mus_sng_title"], source:[], title:[], chart_type:"SINGLE_BAR_CHART"}},
        {title:'TOP ARTISTI', payload: {start_date:"",end_date:"",group_by:["mus_art_name"], source:[], title:[],chart_type:"SINGLE_BAR_CHART"}},
        {title:'TOP ARTISTI | PROGRAMMI', payload: {start_date:"", end_date:"", group_by:["mus_art_name","title"], source:[], title:[], chart_type:"CONCAT_BAR_CHART"}},
        {title:'TOP CANZONI | PROGRAMMI', payload: {start_date:"", end_date:"", group_by:["mus_sng_title","title"], source:[], title:[], chart_type:"CONCAT_BAR_CHART"}}
    ],
    'advertising': [
        {title:'CANALI', payload: {start_date:"",end_date:"",group_by:["source"],source:[], name:[], chart_type:"PIE_CHART"}},
        {title:'PROGRAMMI', payload: {start_date:"",end_date:"",group_by:["title"],source:[], name:[],chart_type:"PIE_CHART"}},
        {title:'ADVERTISING', payload: {start_date:"",end_date:"",group_by:["name"],source:[], name:[],chart_type:"SINGLE_BAR_CHART"}},
        {title:'FRUIZIONE ADVERTISING', payload: {start_date:"",end_date:"",group_by:["day_param"], source:[], name:[],chart_type:"SINGLE_BAR_CHART"}}
    ],
    'platforms': [
        {title:'CATEGORIA', payload: {start_date:"",end_date:"",group_by:["macro_category"],macro_category:[],platform:[],chart_type:"PIE_CHART"}},
        {title:'AUDIENCE PER PIATTAFORMA', payload: {start_date:"",end_date:"",group_by:["day_param","macro_category"],macro_category:[],platform:[],chart_type:"STACKED_BAR_CHART"}},
        {title:'SISTEMI OPERATIVI', payload: {start_date:"",end_date:"",group_by:["platform"],macro_category:[],platform:[],chart_type:"PIE_CHART"}},
        {title:'VERSIONE SISTEMI OPERATIVI', payload: {start_date:"",end_date:"",group_by:["os_name"],macro_category:[],platform:[],chart_type:"SINGLE_BAR_CHART"}}
    ],
    'social_pages': [
        {title:'HIGHLIGHTS SOCIAL', filtersList:['social_pages_page_name'], payload:{start_date: '', end_date: '', page_name:[], chart_type: 'SCORECARD'}},

        {title:'SOCIAL REACH', filtersList:['social_pages_page_name'], payload: {start_date:"",end_date:"", group_by: ["page_name"], page_name:[], metric_name:["reach","page_engagement"], chart_type: "PIE_CHART"}},
        {title:'SOCIAL IMPRESSIONS', filtersList:['social_pages_page_name'], payload: {start_date:"",end_date:"", group_by: ["page_name"], page_name:[], metric_name:["impressions","page_impressions"], chart_type: "PIE_CHART"}},
        {title:'SOCIAL NUM. CONTENUTI PUBBLICATI', filtersList:['social_pages_page_name'], payload: {start_date:"",end_date:"", group_by: ["page_name"], page_name:[], metric_name:["impressions","page_impressions"], chart_type: "PIE_CHART"}},

        {title:'FACEBOOK', filtersList:['social_pages_page_name','social_pages_facebook_metric_name'], payload:{start_date: '', end_date: '', group_by: ["day_param"], page_name:[], metric_name:["page_post_engagements"], chart_type: "MIXER_BAR_LINE_CHART"}},
        {title:'INSTAGRAM', filtersList:['social_pages_page_name','social_pages_instagram_metric_name'], payload:{start_date: '', end_date: '', group_by: ["day_param"], page_name:[], metric_name:["reach"], chart_type: "MIXER_BAR_LINE_CHART"}},
        {title:'TIKTOK', filtersList:['social_pages_page_name','social_pages_tiktok_metric_name'], payload:{start_date: '', end_date: '', group_by: ["day_param"], page_name:[], metric_name:[], chart_type: "MIXER_BAR_LINE_CHART"}},
        
        {title:'POST FACEBOOK PER UTENTI RAGGIUNTI', hideColumns: ['post_impressions_fan', 'post_clicks', 'post_impressions_viral', 'post_reactions_love_total'], columnTableList: ['post_title', 'post_impressions', 'post_impressions_unique', 'post_impressions_fan', 'post_clicks', 'post_reactions_like_total', 'post_impressions_viral', 'post_reactions_love_total'], filtersList:['social_pages_page_name'], payload: {start_date:'', end_date:'', group_by:["post_title","metric_name"], page_name:[], source_social:["Facebook"], metric_name:['post_impressions', 'post_impressions_unique', 'post_impressions_fan', 'post_clicks', 'post_reactions_like_total', 'post_impressions_viral', 'post_reactions_love_total'], chart_type:"SOCIAL_TABLE_CHART"}},
        {title:'POST E REEL INSTAGRAM PER UTENTI RAGGIUNTI', hideColumns: ['shares', 'comments'], columnTableList: ['post_title', 'impressions', 'reach', 'likes', 'shares','comments'], filtersList:['social_pages_page_name'], payload: {start_date:'', end_date:'', group_by:["post_title","metric_name"], page_name:[], source_social:["Instagram"], metric_name:['impressions', 'reach', 'likes', 'shares', 'comments'], chart_type:"SOCIAL_TABLE_CHART"}}
    ],
    'uae': [
        {title:'GIORNO', payload: {start_date:"",end_date:"", granularity:'DAY', group_by:[], chart_type: "MIXED_LINE_BARS_CHART"}},
        {title:'MESE', payload: {start_date:"",end_date:"", granularity:'MONTH', group_by:[], chart_type: "MIXED_LINE_BARS_CHART"}}
    ]
};

export const DefaultValues = {
    social_pages_facebook_metric_name: "page_post_engagements",
    social_pages_instagram_metric_name: "reach",
    contenuti_live_hours: "Ultime 6 ore (15 min.)",
    timezone: "UTC"
}

export const FilterData = {
    'overview': {},
    'audience': {table_name: 'audience', columns: ['name', 'macro_category'], columns_alias:['Canale', 'Piattaforma'],  type: 'LIVE'},
    'real_time': {table_name: 'real_time', columns: [], localFilters:["contenuti_live_hours", "timezone"], columns_alias: ['Intervallo temporale', 'Time zone']},
    'contenuti_live': {table_name: 'contenuti_live', columns: ['name', 'program_title'], columns_alias: ['Canali', 'Programma']},
    'contenuti_on_demand': {table_name: 'contenuti_on_demand', columns: ['channel', 'series','program'], columns_alias: ['Canale', 'Serie','Programma']},
    'music': {table_name:'music', columns: ['source','title'], columns_alias: ['Canale','Programma']},
    'advertising': {table_name:'advertising', columns: ['source','name'], columns_alias: ['Canale','ADV']},
    'platforms': {table_name:'platforms', columns: ['macro_category','platform'], columns_alias: ['Piattaforma','Sistema operativo']},
    'social_pages': {table_name:'social_pages', columns:["page_name", "metric_name"],
        localFilters:["social_pages_page_name", "social_pages_facebook_metric_name", "social_pages_instagram_metric_name", "social_pages_tiktok_metric_name"],
        columns_alias: ['Pagina']},
    'uae': {table_name: 'uae', columns: [], columns_alias: []}
}