import axios from 'axios';

const getEntitiesData = async (getToken, idDashboard, requestBody: any) => {
    try {
        requestBody.token = getToken();        
        let url = process.env.REACT_APP_BACKEND_URL

        if(idDashboard === 'uae') url = url +'uae/get_uae_values'
        else url = url + idDashboard.toLowerCase() + '/' +((idDashboard.toLowerCase() === 'overview')?requestBody.end_point.toLowerCase():'')

        const response = axios.post(url, requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json',
                    'Access-Control-Allow-Origin': "*"
                }
            });
        return response;  // Adjusted to return the correct part of the response
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};

export const fecthDataGraph = (currentDashboardGraph, getToken, idDashboard, listGraph, setDataGraph) => {
    if(currentDashboardGraph && currentDashboardGraph.length > 0) {
        listGraph.forEach((el) => {
            let currentEndPoint = (idDashboard.toLowerCase() === 'social_pages' && currentDashboardGraph[el].payload.chart_type === "SOCIAL_TABLE_CHART")? 'social_contents': idDashboard;

            getEntitiesData(getToken, currentEndPoint, currentDashboardGraph[el].payload)
            .then((response) => {
                let newResponse = response
                if(newResponse.data.type === 'PIE_CHART')
                    newResponse.data = {data: newResponse.data}

                if(newResponse.data.data.length > 0) {
                    setDataGraph(el, newResponse.data)}
                else if (Object.keys(newResponse.data.data).length > 0) setDataGraph(el, newResponse.data)
                else if (newResponse.data.type === "SOCIAL_TABLE_CHART") setDataGraph(el, newResponse.data)
            })
            .catch((error) => {                
                // console.log(error);
            })
        });
    }
};