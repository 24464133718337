import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import '../../style/home.css';
import { Chip } from '@mui/material';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AutocompleteFilter = ({ disableClearable=false, showSelected, filter_style, allSelected, value = [], options, caption, name, setFilterValueList, multiple=true, className='custom-filter' }) => {

    const handleChange = (event: any, newValue: {name: string, id: string}[]) => {
        event.preventDefault();
        if (event.target.lastChild !== null)
            setFilterValueList(name, newValue, event.target.lastChild.data);
        else if (newValue.at(-1))
            setFilterValueList(name, newValue, newValue.at(-1)!.id);
        else setFilterValueList(name, newValue, null);
    };

    return (
            <Autocomplete
                disableClearable={disableClearable}
                className={className}
                multiple={(options.length===0)?true:multiple}
                disableCloseOnSelect
                options={[...options]}
                getOptionLabel={(option) => option.id}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={handleChange}
                value={value}
                limitTags={1}
                sx={{ width: '100% !important' }}  // Add maxWidth to limit the size
                renderInput={(params) => 
                    <TextField {...params} label={(options.length>0)?caption.replace('_',' '):'Caricamento ...'} variant="outlined"
                        sx={{...filter_style}}
                    />}
                renderTags={(selected, getTagProps) => {
                    const maxVisible = (showSelected)?1:0;
                    return (
                    <>
                        {selected.slice(0, maxVisible).map((option, index) => (
                            <Chip key={`chip-`+index}
                                label={(option.name.length>15)?option.name.slice(0, 15)+'...':option.name}                            
                            />
                        ))}
                        {selected.length > maxVisible && (
                            <Chip key={`chip-extra`} label={`+ ${selected.length - maxVisible}`} />
                        )}
                    </>
                    );
                }}
                renderOption={(props, option, { selected }) => {
                    return (
                        <li {...props} key={`option-${option.id}`}>
                            {multiple && <Checkbox
                                key={`checkbox-${option.id}`}
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8, color: 'white' }}
                                checked={selected || allSelected}
                                onChange={(event) => handleChange(event, option)}
                            />}
                            {option.name}
                        </li>
                    );
                }}
            />
    );
};

export default AutocompleteFilter;
