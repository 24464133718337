import React from 'react';
import EChartsReact from "echarts-for-react";
import { formatGrouppedLegend, formatNumber, formatNumLabel, generateColorPalette, generateColorPalettePie } from '../../utils/graphUtils.tsx';
import '../../../style/graph.css'; // Importa il file CSS

const BarChartWithLabelsComponent = ({ data, color = 'red', sizeGraph='large', splitted=false, sort = true, showLabel= false, perc = false}) => {
    // Ordina i valori in ordine crescente e mantieni l'associazione con le categorie
    let sortedData = (data && data['yAxis']) ? data['yAxis'].map((value, index) => ({
        value,
        name: data['xAxis'][index]
    })):[]

    sortedData = (sort)? sortedData.sort((a, b) => {
      if (a.name && a.name.toLowerCase() === 'others') return -1; // 'other' viene sempre dopo
      if (b.name && b.name.toLowerCase() === 'others') return 1; // 'other' viene sempre dopo
      return a.value - b.value; // Ordine crescente per gli altri elementi
    }) :sortedData;


    const sortedValues = sortedData.map(item => item.value);
    const sortedSubcategories = sortedData.map(item => item.name);

    // Genera una scala di colori rossi
    const colors = (!splitted)? generateColorPalette(sortedValues, color): generateColorPalettePie(sortedSubcategories.reverse(), sortedValues, color).reverse()

    // Determina l'altezza dinamica del grafico in base al numero di categorie
    const dynamicHeight = Math.max(sortedSubcategories.length * 30, 400);  

    const option = {
        tooltip: {
            textStyle: {                
                fontFamily: 'Custom_font',                
                align: 'left' // Allinea il testo del tooltip a sinistra
            },
            formatter: (params) => {
                if (!perc) return `<div style="display: flex; justify-content: space-between; width: 100%; gap: 20px;"><span>${params[0].name}</span><strong style="margin-left: auto; text-align: right;">${formatNumber(params[0].value)}</strong>`;
                else return `<div style="display: flex; justify-content: space-between; width: 100%; gap: 20px;"><span>${params[0].name}</span><strong style="margin-left: auto; text-align: right;">${formatNumber(params[0].value)}%</strong>`;
            },
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
                label: {
                    color: '#ffffff' // Colore del testo del tooltip
                }
            },
            confine: true, // Mantiene il tooltip all'interno del container
            extraCssText: 'max-width: 800px; white-space: normal;' // Limita la larghezza e consente testo su più righe
        },
        grid: {
            top: '0%',
            bottom: '0%',
            left: '10%',
            right: '5%',
            containLabel: true
        },
        xAxis: [
            {  
                type: 'value',
                axisLabel: {
                    formatter: (value) => (perc)?`${formatNumLabel(value)}%`:`${formatNumLabel(value)}`, // Aggiungi '%' al valore dell'asse x
                    fontFamily: 'Custom_font',
                    alignWithLabel: true,
                    color: '#fff' // Colore delle etichette dell'asse X
                },
                splitLine: {
                    show: false // Nascondi le righe di suddivisione dell'asse x
                }
            }
        ],
        yAxis: [
            {  
                type: 'category',
                data: (!splitted)?sortedSubcategories || []: sortedSubcategories.reverse() || [],
                axisTick: {                    
                    alignWithLabel: true
                },
                axisLabel: {
                    formatter: (value) => formatGrouppedLegend(value,(sizeGraph==='small')?30:70),
                    fontFamily: 'Custom_font',
                    color: '#fff', // Colore delle etichette dell'asse Y
                    interval: 0, // Mostra tutte le etichette
                },
                //name: data['label'].padEnd(data['label'].length+(data['label'].length/2),' '),
                nameLocation: 'end', // Posizione del nome dell'asse Y
                nameTextStyle: { 
                    fontSize: 14, // Dimensione del testo del nome dell'asse Y
                    color: '#fff' // Colore del nome dell'asse Y
                },
                splitLine: {
                    show: false // Nascondi le righe di suddivisione dell'asse y
                }
            }
        ],
        series: [
            {
                name: 'Value',
                type: 'bar',
                barWidth: '60%',
                data: sortedValues || [],
                label: {
                    show: showLabel, // Mostra le etichette se showLabel è true
                    fontFamily: 'Custom_font',
                    fontSize: 17, // Dimensione del testo del nome dell'asse Y
                    formatter: (value) => (perc)?`${value.value}%`: `${value.value}`,
                    color: '#fff',
                    textBorderColor: '#000', // Colore del bordo del testo (bianco)
                    textBorderWidth: 2, // Larghezza del bordo
                },
                itemStyle: {
                    fontFamily: 'Custom_font',
                    color: ((color === 'neutral')?'red':(params) => colors[params.dataIndex])
                }
            }
        ]
    };

    return (
        <div className="chart-container">
            <EChartsReact option={option} notMerge={true} className={'chart-wrapper'} style={{ height: `${dynamicHeight}px`, width: '95%' }} />
        </div>
    );
}

export default BarChartWithLabelsComponent;
