import React from "react";
import { Grid, Typography, ThemeProvider, Card, CardContent } from "@mui/material";
import { darkTheme } from "../utils/graphUtils.tsx";
import { helpSectionsOverview, helpSectionsAudience, helpSectionsRealTime, helpSectionsLive, helpSectionsOnDemand, helpSectionsMusica, helpSectionsADVERTISING, helpSectionsPIATTAFORME, helpSectionsSocial, helpSectionsUAE } from "../utils/HelpDescriptions.tsx";

export const Help = ({ helpId }) => {

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    try{
        const timer = setTimeout(() => {        
          if(helpId!== undefined && helpId) {
            const element = document.getElementById(helpId);
            if (element) {
              element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
          }
        }, 1000);

        return () => clearTimeout(timer);
    }catch(e){console.log('Elemento in pagina \'Help\' non trovato!')}
  },[helpId])

  return (
    <ThemeProvider theme={darkTheme}>
      <Grid container spacing={3} sx={{ color: "white", textAlign: 'justify' }}>
        <Grid item xs={12}>
          <Typography variant="h4" style={{ color: "white" }}>
              INTRODUZIONE
          </Typography>
          <Typography variant="body1" style={{ color: "white", textAlign: "left", marginBottom: "10px" }}>
            Questa dashboard è organizzata in diverse sezioni per facilitare l'analisi dei dati. Di seguito sono elencate le principali sezioni disponibili:
          </Typography>
          <ul>
            <li>
            <strong>Overview:</strong>
            Una panoramica di tutte le tabs. I dati calcolati in questa dashboard si riferiscono agli ultimi 90 giorni.
            </li>
            <li>
            <strong>Audience:</strong>
            Raccoglie le prestazioni dei canali in termini di contatti lordi (Touchpoints) e utenti.
            </li>
            <li>
            <strong>Real Time:</strong>
            Mostra l'andamento delle sessioni di ascolto streaming digital nel periodo selezionato. È possibile visualizzare i grafici sia in UTC che in ora italiana.
            </li>
            <li>
            <strong>Contenuti Live:</strong>
            Fornisce l'andamento e l'audience dei programmi andati in onda sui canali live.
            </li>
            <li>
            <strong>Contenuti On Demand:</strong>
            Mostra metriche sull'audience e la fruizione dei contenuti on demand.
            </li>
            <li>
            <strong>Musica:</strong>
            Fornisce l'audience e la fruizione delle canzoni andate in onda, sia in assoluto che rispetto ai programmi.
            </li>
            <li>
            <strong>Advertising:</strong>
            Indica la fruizione delle campagne di advertising, sia in assoluto che rispetto ai programmi.
            </li>
            <li>
            <strong>Piattaforme:</strong>
            Indica le piattaforme e il dettaglio dei dispositivi utilizzati per fornire l'audience.
            </li>
            <li>
            <strong>Social:</strong>
            Fornisce metriche relative alle pagine social (Facebook e Instagram) e ai contenuti pubblicati.
            Nota: I dati vengono utilizzati così come forniti dall'API di Meta, che talvolta può restituire informazioni incomplete. Per maggiori dettagli, consulta la documentazione ufficiale:  <a href="https://developers.facebook.com/docs/instagram-platform/reference/instagram-media/insights#:~:text=Se%20gli%20insight%20richiesti%20non%20esistono%20o%20non%20sono%20attualmente%20disponibili%2C%20l%27API%20restituisce%20un%20insieme%20di%20dati%20vuoto%20invece%20di%200%20per%20le%20singole%20metriche%2E" style={{ color: "gray" }} target="_blank" rel="noopener noreferrer">Meta API Insights Reference</a>
            </li>
            <li>
            <strong>UAE (Unità di Ascolto Equivalente):</strong>
            Presenta l'andamento dell'UAE giornaliera e mensile.
            </li>
          </ul>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4" style={{ color: "white" }}>
            Note Generali
          </Typography>
          <Typography variant="body1" style={{ color: "white", textAlign: "left", marginBottom: "10px" }}>
            La timezone UTC viene utilizzata come riferimento per garantire una coerenza tra tutte le sorgenti dati. Di conseguenza, il filtraggio per data e ora deve essere interpretato secondo questo fuso orario. Per alcuni grafici, l'ora locale è disponibile come indicato.
            Si ricorda che l'analisi di grandi periodi temporali può avere un impatto sul costo delle interrogazioni al sistema database (es. BigQuery). Si consiglia di ottimizzare i filtri temporali per migliorare l'efficienza.
          </Typography>
       </Grid>

        <Grid item xs={12}>
          <Typography variant="h4" style={{ color: "white" }}>
              OVERVIEW
          </Typography>
          <Typography variant="body1" style={{ color: "white", textAlign: "left", marginBottom: "10px" }}>
              La sezione Overview offre una panoramica generale dei dati principali raccolti dalle piattaforme digitali.
          </Typography>
          <ul style={{ color: "white", textAlign: "left", paddingLeft: "20px" }}>
              <li>Numero di utenti totali: Include utenti loggati e non loggati da tutte le piattaforme digitali.</li>
              <li>Numero di touchpoints: Contatti lordi registrati.</li>
              <li>Web impressions: Numero totale di impression sul web.</li>
              <li>Percentuale di utenti loggati: percentuale di utenti loggati rispetto al totale delle sessioni di ascolto e visualizzazioni web.</li>
              <li>Periodo di riferimento: Gli ultimi 90 giorni.</li>
          </ul>
        </Grid>
        {
          [...helpSectionsOverview.map((section, index) => {
            let listSection = section.description.split('££')
            return (section.description !== '' )
              ?<Grid item xs={12} key={'helpSectionsOverview_'+index} id={`OVERVIEW_${section.title}`}>
                <Card key={'CardOverview_'+index}
                  elevation={2}
                  sx={{
                    backgroundColor: "#1e1e1e",
                    color: "white",
                    textAlign: "left"
                  }}
                >
                  <CardContent key={'CardContentOverview_'+index}>
                    <Typography key={'titleOverview_'+index} variant="h5">
                      {section.title}
                    </Typography>
                    {(listSection.length > 1)
                      ?<div key={'desIndentOverview_'+index}>
                          <Typography key={'desTypographyOverview_'+index} variant="body1" style={{ color: "white", textAlign: "left", marginBottom: "10px" }}> {listSection[0]} </Typography>
                          <ul key={'ulOverview_'+index} style={{ color: "white", textAlign: "left", paddingLeft: "20px" }}>
                            {listSection.slice(1).map((el, i) => {
                              return <li key={'liOverview_'+index+'_'+i}>{el}</li>})                        
                            } 
                          </ul>
                        </div>
                      :<Typography key={'desOverview_'+index} variant="body1">{section.description}</Typography>
                    }
                  </CardContent>
                </Card>
              </Grid>
              :<div key={'helpSectionsOverview_'+index}/>
            }
          )]
        }

        <Grid item xs={12}>
          <Typography variant="h4" style={{ color: "white" }}>
              AUDIENCE
          </Typography>
        </Grid>
        {
          [...helpSectionsAudience.map((section, index) => {
            return (section.description !== '' )
              ?<Grid item xs={12} key={'helpSectionsAudience_'+index} id={`AUDIENCE_${section.title}`}>
                <Card key={'CardAudience_'+index}
                  elevation={2}
                  sx={{
                    backgroundColor: "#1e1e1e",
                    color: "white",
                    textAlign: "left"
                  }}
                >
                  <CardContent key={'CardContentAudience_'+index}>
                    <Typography key={'titleAudience_'+index} variant="h5">
                      {section.title}
                    </Typography>
                    <Typography key={'desAudience_'+index} variant="body1">{section.description}</Typography>
                  </CardContent>
                </Card>
              </Grid>
              :<div key={'helpSectionsAudience_'+index}/>
            }
          )]
        }

        <Grid item xs={12}>
          <Typography variant="h4" style={{ color: "white" }}>
              REAL TIME
          </Typography>
        </Grid>
        {
          [...helpSectionsRealTime.map((section, index) => {
            let listSection = section.description.split('££')
            return (section.description !== '' )
              ?<Grid item xs={12} key={'helpSectionsRealTime_'+index} id={`REAL_TIME_${section.title}`}>
                <Card key={'CardRealTime_'+index}
                  elevation={2}
                  sx={{
                    backgroundColor: "#1e1e1e",
                    color: "white",
                    textAlign: "left"
                  }}
                >
                  <CardContent key={'CardContentRealTime_'+index}>
                    <Typography key={'titleRealTime_'+index} variant="h5">
                      {section.title}
                    </Typography>
                    {(listSection.length > 1)
                      ?<div key={'desIndentRealTime_'+index}>
                          <Typography key={'desTypographyRealTime_'+index} variant="body1" style={{ color: "white", textAlign: "left", marginBottom: "10px" }}> {listSection[0]} </Typography>
                          <ul key={'ulRealTime_'+index} style={{ color: "white", textAlign: "left", paddingLeft: "20px" }}>
                            {listSection.slice(1).map((el, i) => {
                              return <li key={'liRealTime_'+index+'_'+i}>{el}</li>})                        
                            } 
                          </ul>
                        </div>
                      :<Typography key={'desRealTime_'+index} variant="body1">{section.description}</Typography>
                    }
                  </CardContent>
                </Card>
              </Grid>
              :<div key={'helpSectionsRealTime_'+index}/>
            }
          )]
        }

        <Grid item xs={12}>
          <Typography variant="h4" style={{ color: "white" }}>
              CONTENUTI LIVE
          </Typography>
        </Grid>
        {
          [...helpSectionsLive.map((section, index) => {
            return (section.description !== '' )
              ?<Grid item xs={12} key={'helpSectionsLive_'+index} id={`LIVE_${section.title}`}>
                <Card key={'CardLive_'+index}
                  elevation={2}
                  sx={{
                    backgroundColor: "#1e1e1e",
                    color: "white",
                    textAlign: "left"
                  }}
                >
                  <CardContent key={'CardContentLive_'+index}>
                    <Typography key={'titleLive_'+index} variant="h5">
                      {section.title}
                    </Typography>
                    <Typography key={'desLive_'+index} variant="body1">{section.description}</Typography>
                  </CardContent>
                </Card>
              </Grid>
              :<div key={'helpSectionsLive_'+index}/>
            }
          )]
        }

        <Grid item xs={12}>
          <Typography variant="h4" style={{ color: "white" }}>
              CONTENUTI ON DEMAND
          </Typography>
        </Grid>
        {
          [...helpSectionsOnDemand.map((section, index) => {
            return (section.description !== '' )
              ?<Grid item xs={12} key={'helpSectionsOnDemand_'+index} id={`ON_DEMAND_${section.title}`}>
                <Card key={'CardOnDemand_'+index}
                  elevation={2}
                  sx={{
                    backgroundColor: "#1e1e1e",
                    color: "white",
                    textAlign: "left"
                  }}
                >
                  <CardContent key={'CardContentOnDemand_'+index}>
                    <Typography key={'titleOnDemand_'+index} variant="h5">
                      {section.title}
                    </Typography>
                    <Typography key={'desOnDemand_'+index} variant="body1">{section.description}</Typography>
                  </CardContent>
                </Card>
              </Grid>
              :<div key={'helpSectionsOnDemand_'+index}/>
            }
          )]
        }

        <Grid item xs={12}>
          <Typography variant="h4" style={{ color: "white" }}>
              MUSICA
          </Typography>
        </Grid>
        {
          [...helpSectionsMusica.map((section, index) => {
            return (section.description !== '' )
              ?<Grid item xs={12} key={'helpSectionsMusica_'+index} id={`MUSICA_${section.title}`}>
                <Card key={'CardMusica_'+index}
                  elevation={2}
                  sx={{
                    backgroundColor: "#1e1e1e",
                    color: "white",
                    textAlign: "left"
                  }}
                >
                  <CardContent key={'CardContentMusica_'+index}>
                    <Typography key={'titleMusica_'+index} variant="h5">
                      {section.title}
                    </Typography>
                    <Typography key={'desMusica_'+index} variant="body1">{section.description}</Typography>
                  </CardContent>
                </Card>
              </Grid>
              :<div key={'helpSectionsMusica_'+index}/>
            }
          )]
        }

        <Grid item xs={12}>
          <Typography variant="h4" style={{ color: "white" }}>
              ADVERTISING
          </Typography>
        </Grid>
        {
          [...helpSectionsADVERTISING.map((section, index) =>{
            return (section.description !== '' )
              ?<Grid item xs={12} key={'helpSectionsADVERTISING_'+index} id={`ADVERTISING_${section.title}`}>
                <Card key={'CardADVERTISING_'+index}
                  elevation={2}
                  sx={{
                    backgroundColor: "#1e1e1e",
                    color: "white",
                    textAlign: "left"
                  }}
                >
                  <CardContent key={'CardContentADVERTISING_'+index}>
                    <Typography key={'titleADVERTISING_'+index} variant="h5">
                      {section.title}
                    </Typography>
                    <Typography key={'desADVERTISING_'+index} variant="body1">{section.description}</Typography>
                  </CardContent>
                </Card>
              </Grid>
              :<div key={'helpSectionsADVERTISING_'+index}/>
            }
          )]
        }

        <Grid item xs={12}>
          <Typography variant="h4" style={{ color: "white" }}>
              PIATTAFORME
          </Typography>
        </Grid>
        {
          [...helpSectionsPIATTAFORME.map((section, index) => {
            return (section.description !== '' )
              ?<Grid item xs={12} key={'helpSectionsPIATTAFORME_'+index} id={`PIATTAFORME_${section.title}`}>
                <Card key={'CardPIATTAFORME_'+index}
                  elevation={2}
                  sx={{
                    backgroundColor: "#1e1e1e",
                    color: "white",
                    textAlign: "left"
                  }}
                >
                  <CardContent key={'CardContentPIATTAFORME_'+index}>
                    <Typography key={'titlePIATTAFORME_'+index} variant="h5">
                      {section.title}
                    </Typography>
                    <Typography key={'desPIATTAFORME_'+index} variant="body1">{section.description}</Typography>
                  </CardContent>
                </Card>
              </Grid>
              :<div key={'helpSectionsPIATTAFORME_'+index}/>
            }
          )]
        }

        <Grid item xs={12}>
          <Typography variant="h4" style={{ color: "white" }}>
              SOCIAL
          </Typography>
        </Grid>
        {
          [...helpSectionsSocial.map((section, index) => {
            let listSection = section.description.split('££')
            return (section.description !== '' )
              ?<Grid item xs={12} key={'helpSectionsSocial_'+index} id={`SOCIAL_${section.title}`}>
                <Card key={'CardSocial_'+index}
                  elevation={2}
                  sx={{
                    backgroundColor: "#1e1e1e",
                    color: "white",
                    textAlign: "left"
                  }}
                >
                  <CardContent key={'CardContentSocial_'+index}>
                    <Typography key={'titleSocial_'+index} variant="h5">
                      {section.title}
                    </Typography>
                    {(listSection.length > 1)
                      ?<div key={'desIndentSocial_'+index}>
                          <Typography key={'desTypographySocial_'+index} variant="body1" style={{ color: "white", textAlign: "left", marginBottom: "10px" }}> {listSection[0]} </Typography>
                          <ul key={'ulSocial_'+index} style={{ color: "white", textAlign: "left", paddingLeft: "20px" }}>
                            {listSection.slice(1).map((el, i) => {
                              return <li key={'liSocial_'+index+'_'+i}>{el}</li>})                        
                            } 
                          </ul>
                        </div>
                      :<Typography key={'desSocial_'+index} variant="body1">{section.description}</Typography>
                    }
                  </CardContent>
                </Card>
              </Grid>
              :<div key={'helpSectionsSocial_'+index}/>
            }
          )]
        }

        <Grid item xs={12}>
          <Typography variant="h4" style={{ color: "white" }}>
              UAE
          </Typography>
        </Grid>
        {
          [...helpSectionsUAE.map((section, index) => {
            return (section.description !== '' )
              ?<Grid item xs={12} key={'helpSectionsUAE_'+index} id={`UAE_${section.title}`}>
                <Card key={'CardUAE_'+index}
                  elevation={2}
                  sx={{
                    backgroundColor: "#1e1e1e",
                    color: "white",
                    textAlign: "left"
                  }}
                >
                  <CardContent key={'CardContentUAE_'+index}>
                    <Typography variant="h5" key={'titleUAE_'+index}>
                      {section.title}
                    </Typography>
                    <Typography key={'des_'+index} variant="body1">{section.description}</Typography>
                  </CardContent>
                </Card>
              </Grid>
              :<div key={'helpSectionsUAE_'+index}/>
            }
          )]
        }

      </Grid>
    </ThemeProvider>
  );
};

export default Help;