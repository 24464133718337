import React from 'react';
import ReactECharts from 'echarts-for-react';

import { calculateAverage, formatDataLabel, formatGrouppedLegend, formatNumber, formatNumLabel, getColorBasedOnIndex, formatDateTime } from "../../utils/graphUtils.tsx";
import { useMediaQuery, useTheme } from '@mui/material';

// Componente React per il grafico
const StackedBarChart = ({ data, color = 'red', date = false, showLegend=true, yType = 'data', filtroData = '' }) => {

    // Ordina le serie in base alla media
    const sortedSeries = data.seriesData.map(seriesItem => ({
            ...seriesItem,
            average: calculateAverage(seriesItem.data)
        })).sort((a, b) => a.average - b.average);
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.down('lg')); // Usa il breakpoint per determinare la larghezza dello schermo
    const isXLargeScreen = useMediaQuery(theme.breakpoints.down('xl')); // Usa il breakpoint per determinare la larghezza dello schermo
    
    // Configurazione dell'opzione per il grafico
    const option = {
        tooltip: {
            trigger: 'item',
            textStyle: {
                fontFamily: 'Custom_font',
                align: 'left'
            },
            z: 999, // Imposta un alto z-index per garantire che il tooltip sia sempre visibile
            axisPointer: {
                type: 'shadow' // Mostra ombreggiatura per una migliore visibilità
            },
            formatter: (params) => {
                return `
                    <div style="display: flex; flex-direction: column; align-items: flex-start;">
                        <div>${params.seriesName}</div>
                        <div style="display: flex; justify-content: space-between; width: 100%; gap: 20px;">
                            <span>${formatDataLabel(params.name, yType, filtroData)}</span>
                            <strong style="margin-left: auto; text-align: right;">${formatNumber(params.value)}</strong>
                        </div>
                    </div>
                `;
            }
        },
        legend: {
            show: showLegend,
            formatter: formatGrouppedLegend,
            type: 'scroll',
            orient: "vertical",
            top: "center",
            left: "left",
            textStyle: { 
                fontFamily: 'Custom_font',
                color: '#fff' // Colore dei label della legenda
            },
            pageIconColor: '#fff', // Colore delle frecce di scorrimento
            pageTextStyle: {
                color: '#fff' // Colore del numero di pagina
            },
            data: (sortedSeries || []).slice().reverse() || [] // Inverti l'ordine dei dati della legenda
        },
        grid: {
            top: '5%',
            bottom: '0%',
            left: (isLargeScreen)?'30%':(isXLargeScreen)?'25%':'20%',
            right: '2%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            data: data.xAxisData || [], // Imposta i dati dell'asse X
            axisLabel: {
                fontFamily: 'Custom_font',
                formatter: (value) => (date) ? formatDateTime(value, filtroData) : formatDataLabel(value),
                color: '#fff' // Imposta il colore delle etichette dell'asse X su bianco
            },
            axisLine: {
                lineStyle: {
                    color: '#fff' // Colore della linea dell'asse x
                }
            },
            splitLine: {
                show: false // Nascondi le righe di suddivisione dell'asse x
            }
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                fontFamily: 'Custom_font',
                formatter: formatNumLabel,
                color: '#fff' // Imposta il colore delle etichette dell'asse Y su bianco
            },
            axisLine: {
                Style: {
                    color: '#fff' // Colore della linea dell'asse y 
                }
            },
            axisTick: {
                lineStyle: {
                    color: '#fff' // Colore dei segni dell'asse y 
                }
            },
            splitLine: {
                show: false // Nascondi le righe di suddivisione dell'asse y
            }
        },
        series: sortedSeries.map((seriesItem, index) => ({
            name: formatDataLabel(seriesItem.name),
            type: 'bar',
            stack: 'total',
            label: { 
                fontFamily: 'Custom_font',
                show: false,
                color: '#fff' // Imposta il colore delle etichette delle barre su bianco
            },
            itemStyle: {
                fontFamily: 'Custom_font',
                color: getColorBasedOnIndex(index, sortedSeries.length - 1, color)
            },
            emphasis: {
                focus: 'series'
            },
            data: seriesItem.data || []
        }))
    };

    return <ReactECharts option={option} notMerge={true} style={{ height: '400px', width: '100%' }} />;
};

export default StackedBarChart;
