import React from "react";
import { Skeleton } from "@mui/material";
import '../../style/home.css';

const SkeletonComponent = ({ height = '500px' }) => {
    return (
        <div style={{
            position: 'relative',
            width: '100%',
            height: height
        }}>
            <Skeleton
                className="skeleton"
                variant="rectangular"
                width={'100%'}
                height={height}
            />
            <div style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                color: 'white',
                fontSize: '1.5em',
                fontWeight: 'bold',
                textAlign: 'center'
            }}>
                In caricamento...
            </div>
        </div>
    );
}

export default SkeletonComponent;
