import React from 'react';
import axios from 'axios';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import DashboardContainer from './DashboardContainer.tsx';
import { FilterProvider } from "../providers/FilterProvider.tsx";
import '../../style/home.css';
import '../../style/header.css';
import '../../style/footer.css';
import Footer from '../elements/Footer.tsx';
import Header from '../elements/Header.tsx';
import CustomSnackbar from '../utils/SnackBarCustomComponent.tsx';
import useAuth from "../hooks/useAuth.ts";
import MainCard from '../elements/MainCard.tsx';
import { CustomBreakPoint } from '../utils/CustomBreakPoint.tsx';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function MainPageWrapper() {
    const { user } = useAuth();
    const [filteredEntityList, setFilteredEntityList] = React.useState<any>(null);
    const [value, setValue] = React.useState(0);
    const [helpId, setHelpId] = React.useState<any>(null);
    const [isError, setIsError] = React.useState({label: '', message: ''});
    const [open, setOpen] = React.useState(false);

    let entityList= [{label:'Overview', title: 'overview - 90 giorni', id: 'overview'},
        {label: 'Audience', title: 'audience', id: 'audience'},
        {label: 'Real Time', title: 'real time', id: 'real_time'},
        {label: 'Live', title: 'contenuti live', id: 'contenuti_live'},
        {label: 'On Demand', title: 'contenuti on demand', id: 'contenuti_on_demand'},
        {label: 'Musica', title: 'musica', id: 'music'},
        {label: 'Advertising', title: 'advertising', id: 'advertising'},
        {label: 'Piattaforme', title: 'piattaforme', id: 'platforms'},
        {label: 'Social', title: 'social', id:'social_pages'},
        {label: 'UAE', title: 'uae', id:'uae'},
        {label: 'Impostazioni', title: 'impostazioni', id:'settings'},
        {label: 'Aiuto', title: 'aiuto', id:'help'},
    ];
    const isLargeScreen = useMediaQuery(CustomBreakPoint.breakpoints.down('xxl')); // Usa il breakpoint per determinare la larghezza dello schermo
    const refs = React.useRef<any>(entityList.map(() => React.createRef()));
    
    const getUserData = () => {
      return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_BACKEND_URL + 'users', {email:user?.email} )
          .then((response) => {
            resolve({ data: response.data });
          })
          .catch((error) => {
            reject(error);
          });
      });
    };

    function callMutationUserData() {
      getUserData()
      .then((response: any) => {
        let userData = response.data[0];
        let newEntityList = entityList.filter(el => userData[el.label.replace(' ', '_').toLowerCase()])
        setFilteredEntityList(newEntityList)
      })
      .catch((error) => { console.log(error); setFilteredEntityList([]) });
    }

    const handleChange = (event, newValue) => {
      try{
        setValue(newValue);
        setHelpId(null);
        if(refs.current[newValue] && refs.current[newValue].current)
          refs.current[newValue].current.scrollIntoView({ behavior: 'smooth', block: "center", inline: "center" });
      } catch(e) {
          setValue(0); // Resetta se non è valida
      }
    };

    const handleChangeHelp = (id) => {
      try{
        let newIndex = filteredEntityList.findIndex(el => el.label === 'Aiuto')
        if(newIndex > -1) {
          setValue(newIndex);
          setHelpId(id);
        }    
      } catch(e) { }
    };

    React.useEffect(()=> {
      callMutationUserData() 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(()=> {
      if(isError.label !== '')
        {setOpen(true)}     
    }, [isError])

    return (
      <FilterProvider>
        {filteredEntityList && filteredEntityList.length > 0
          ?
          <Box id='myDiv' className='screen' >
          
            {filteredEntityList && filteredEntityList.length > 0 && <Header entityList={filteredEntityList} value={value} handleChange={handleChange} a11yProps={a11yProps} refs={refs} />}

            {filteredEntityList && filteredEntityList.map((tabElement, index) => {
                return <TabPanel key={'TabPanel'+index} value={value} index={index}>
                  <DashboardContainer indexDashboard={index} idDashboard={tabElement['id']} setIsError = {setIsError} isLargeScreen={isLargeScreen} helpId={helpId} handleChange={handleChangeHelp} />
                </TabPanel>
              }
            )}

            {(filteredEntityList && filteredEntityList.length > 0) && <Footer entityList={filteredEntityList} value={value} handleChange={handleChange} a11yProps={a11yProps}/>}
            
          </Box>
        :
          <div id='myDiv' className='screen' style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            <div style={{ flex: '0 0 auto' }}>
                {filteredEntityList && filteredEntityList.length === 0 && <Header entityList={filteredEntityList} value={value} handleChange={handleChange} a11yProps={a11yProps} refs={refs}/>}
            </div>
            
            <div style={{ flex: 1, overflowY: 'auto' }}>
              {filteredEntityList && filteredEntityList.length === 0 && <Grid container direction="column" sx={{ justifyContent: "center",  alignItems: "center" }}>
                <MainCard element={<Typography style={{color:'var(--text-color)'}}>Non possiedi permessi per visualizzare le dashboards. Per favore contatta un amministratore</Typography>} title={''}></MainCard>              
              </Grid>}
            </div>
            
          </div>
          
        }

        <CustomSnackbar open={open} setOpen={setOpen} message={isError.message} correct={false}/>

      </FilterProvider>
    );
}

export default MainPageWrapper;
