export const filter_style = {
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: '15px',
      borderColor: 'gray', // Colore del bordo normale
    },
    '&:hover fieldset': {
      borderColor: 'White', // Colore del bordo al passaggio del mouse
    },
    '&.Mui-focused fieldset': {
      borderColor: 'gray', // Colore del bordo quando è attivo
    },
    '& .MuiInputBase-input': {
        color: 'white', // Assicurati che il colore del testo all'interno dell'input sia bianco
        fontWeight: 'bold', // Grassetto quando è attivo
    }, 
    '&.Mui-focused:not(:focus-within) fieldset': {
      borderColor: 'gray', // Mantieni il bordo grigio quando selezionato ma senza focus
    },
  },
  '& .MuiInputLabel-root': {
      color: 'White', // Colore bianco quando è attivo
      fontWeight: 'bold', // Grassetto quando è attivo
  '&.Mui-focused': {
      color: 'White', // Colore bianco quando è attivo
      fontWeight: 'bold', // Grassetto quando è attivo     
  },
  },
}

export const onairLocalFilter = [
  {
    "name": "contenuti_live_hours",
    "id": "contenuti_live_hours",
    "optionList": [
        {
            "name": "Ultime 3 ore (15 min.)",
            "id": "Ultime 3 ore (15 min.)",
            "root": []
        },
        {
            "name": "Ultime 6 ore (15 min.)",
            "id": "Ultime 6 ore (15 min.)",
            "root": []
        },
        {
            "name": "Ultime 12 ore (15 min.)",
            "id": "Ultime 12 ore (15 min.)",
            "root": []
        },
        {
            "name": "Ultime 24 ore (60 min.)",
            "id": "Ultime 24 ore (60 min.)",
            "root": []
        },
        {
            "name": "Ultima settimana (6 ore)",
            "id": "Ultima settimana (6 ore)",
            "root": []
        }
    ],
    "type": "selector",
    "isAllSelected": false
  }
]

export const onairTimeZoneFilter = [
  {
    "name": "timezone",
    "id": "timezone",
    "optionList": [
        {
            "name": "UTC",
            "id": "UTC",
            "root": []
        },
        {
            "name": "Ora italiana",
            "id": "Ora italiana",
            "root": []
        }      
    ],
    "type": "selector",
    "isAllSelected": false
  }
]