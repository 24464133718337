import React from "react";
import { Grid, ThemeProvider } from "@mui/material";
import StackedBarChartComponent from "../graphs/BarChart/StackedBarChartComponent.tsx";
import SkeletonComponent from "../utils/Skeleton.tsx";
import useAuth from "../hooks/useAuth.ts";
import { fecthDataGraph} from "../utils/promiseUtils.tsx";
import MainIconCard from "../elements/MainIconCard.tsx";
import { darkTheme } from "../utils/graphUtils.tsx";
import { helpSectionsRealTime } from "../utils/HelpDescriptions.tsx";

export const OnAir = ({ currentDashboardGraph, indexDashboard, idDashboard, isLargeScreen, handleChange }) => {
    const [dataGraph1, setDataGraph1] = React.useState<any>(null)
    const contenuti_live_hoursRef = React.useRef<any>(null);
    const {getToken} = useAuth();

    const setDataGraph = ( index, responseData ) => {
        switch(index){
            case 0:
                try{
                    contenuti_live_hoursRef.current = currentDashboardGraph[0].payload.contenuti_live_hours
                }catch(e){}
                setDataGraph1(JSON.parse(JSON.stringify({ title: currentDashboardGraph[index].title, data: responseData.data })));break;
        }
    }

    React.useEffect(() => {
        setDataGraph1({})
        fecthDataGraph(currentDashboardGraph, getToken, idDashboard, [0], setDataGraph);
    }, [currentDashboardGraph]);

    return (
        <ThemeProvider theme={darkTheme}>
            <Grid container
                direction="column"
                justifyContent="center"
                alignItems="stretch"
                gap='20px'>

                <Grid item
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    rowGap={'20px'}
                >  
                    <Grid item xs={12}>{(dataGraph1 && dataGraph1.data && "string" !== typeof dataGraph1.data)
                        ?<MainIconCard element={<StackedBarChartComponent data={dataGraph1.data} date={true} yType="timestamp" filtroData={contenuti_live_hoursRef.current}/>} title={dataGraph1.title} helpId={'REAL_TIME_'+currentDashboardGraph[0].title} handleChange={handleChange} tooltip={helpSectionsRealTime[0].description} icon={<div className="pulsing-dot"></div>} />
                        :<SkeletonComponent height={'514px'}/>}
                    </Grid>
                </Grid>

            </Grid>
        </ThemeProvider>
    )
}