import React from "react";
import { Grid, InputAdornment, TextField, ThemeProvider } from "@mui/material";
import MainCard from "../elements/MainCard.tsx";
import ScoreCardComponent from "../elements/ScoreCardComponent.tsx";
import SkeletonComponent from "../utils/Skeleton.tsx";
import PieChartComponent from "../graphs/PieChart/PieChartComponent.tsx";
import MixedChartComponent from "../graphs/MixedChart/MixedChartComponent.tsx";
import TableComponent from "../elements/TableComponent.tsx";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import MainIconCard from "../elements/MainIconCard.tsx";
import { darkTheme } from "../utils/graphUtils.tsx";
import { fecthDataGraph } from "../utils/promiseUtils.tsx";
import useAuth from "../hooks/useAuth.ts";
import { TikTokIcon } from "../utils/tiktokIcon.tsx";
import SearchIcon from '@mui/icons-material/Search';
import { GraphData } from "../elements/DashboardElement.tsx";
import { helpSectionsSocial } from "../utils/HelpDescriptions.tsx";

export const Social = ({ currentDashboardGraph, indexDashboard, idDashboard, isLargeScreen, filtersList, handlerUpdate, handleChange }) => {
    const {getToken} = useAuth();
    const socialList = ['facebook','instagram'];    
    const [dataGraph1, setDataGraph1] = React.useState<any>(null)
    const [dataGraph2, setDataGraph2] = React.useState<any>(null)
    const [dataGraph3, setDataGraph3] = React.useState<any>(null)
    const [dataGraph4, setDataGraph4] = React.useState<any>(null)
    const [dataGraph5, setDataGraph5] = React.useState<any>(null)
    const [dataGraph6, setDataGraph6] = React.useState<any>(null)
    const [dataGraph7, setDataGraph7] = React.useState<any>(null)
    const [dataGraph8, setDataGraph8] = React.useState<any>(null)
    const [dataGraph9, setDataGraph9] = React.useState<any>(null)

    React.useEffect(() => {
        fecthDataGraph(currentDashboardGraph, getToken, idDashboard, [0,1,2,3,4,5,6,7,8], setDataGraph);
    }, [currentDashboardGraph]);

    const setDataGraph = ( index, responseData ) => {
        switch(index){
            case 0: setDataGraph1(JSON.parse(JSON.stringify({ title: currentDashboardGraph[index].title, data: responseData.data })));break;
            case 1: setDataGraph2(JSON.parse(JSON.stringify({ title: currentDashboardGraph[index].title, data: responseData.data })));break;
            case 2: setDataGraph3(JSON.parse(JSON.stringify({ title: currentDashboardGraph[index].title, data: responseData.data })));break;
            case 3: setDataGraph4(JSON.parse(JSON.stringify({ title: currentDashboardGraph[index].title, data: responseData.data })));break;
            case 4: setDataGraph5(JSON.parse(JSON.stringify({ title: currentDashboardGraph[index].title, data: responseData.data })));break;
            case 5: setDataGraph6(JSON.parse(JSON.stringify({ title: currentDashboardGraph[index].title, data: responseData.data })));break;
            case 6: setDataGraph7(JSON.parse(JSON.stringify({ title: currentDashboardGraph[index].title, data: responseData.data })));break;
            case 7: setDataGraph8(JSON.parse(JSON.stringify({ title: currentDashboardGraph[index].title, data: responseData.data })));break;
            case 8: setDataGraph9(JSON.parse(JSON.stringify({ title: currentDashboardGraph[index].title, data: responseData.data })));break;
        }
    }

    const handleReduce = (list) => {
        return list.reduce((obj, [key, value]) => {
            obj[key] = value;
            return obj;
        }, {});
    } 

    return (
        <ThemeProvider theme={darkTheme}>
            <Grid container
                direction="column"
                justifyContent="center"
                alignItems="stretch"
                gap='20px'>

                <Grid item xs={12} >{(dataGraph1 && dataGraph1.data && "string" !== typeof dataGraph1.data)
                    ?<MainCard element={<ScoreCardComponent data={dataGraph1.data} direction="horizontal"/>} title={dataGraph1.title} helpId={'SOCIAL_'+currentDashboardGraph[0].title} handleChange={handleChange} tooltip={helpSectionsSocial[0].description}/>
                    :<SkeletonComponent height='200px'/>}
                </Grid>

                <Grid item
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    rowGap={'20px'}
                    >
                        <Grid item xs={12} md={12} lg={(12/socialList.length)-0.05}>{(dataGraph2 && dataGraph2.data && "string" !== typeof dataGraph2.data)?<MainCard element={<PieChartComponent data={dataGraph2.data} sizeGraph={'small'} isLargeScreen={isLargeScreen}/>} title={dataGraph2.title} helpId={'SOCIAL_'+currentDashboardGraph[1].title} handleChange={handleChange} tooltip={helpSectionsSocial[1].description}/> :<SkeletonComponent/>} </Grid>
                        <Grid item xs={12} md={12} lg={(12/socialList.length)-0.05}>{(dataGraph3 && dataGraph3.data && "string" !== typeof dataGraph3.data)?<MainCard element={<PieChartComponent data={dataGraph3.data} sizeGraph={'small'} isLargeScreen={isLargeScreen}/>} title={dataGraph3.title} helpId={'SOCIAL_'+currentDashboardGraph[2].title} handleChange={handleChange} tooltip={helpSectionsSocial[2].description}/> :<SkeletonComponent/>} </Grid>
                        {/* <Grid item xs={12} md={12} lg={(12/socialList.length)-0.05}>{(dataGraph4 && dataGraph4.data)?<MainCard element={<PieChartComponent data={dataGraph4.data} sizeGraph={'small'} isLargeScreen={isLargeScreen}/>} title={dataGraph4.title} helpId={'SOCIAL_'+currentDashboardGraph[3].title} handleChange={handleChange} tooltip={helpSectionsSocial[3].description}/> :<SkeletonComponent/>} </Grid> */}
                </Grid>

                <Grid item
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    rowGap={'20px'}
                    >
                    <Grid item xs={8}>
                        <div className="overview-tab-section">{'Insights sulle pagine'}</div>
                    </Grid>
                    <Grid item container xs={4} sx={{ justifyContent: 'flex-end' }}>
                        <button className={"button-query"} onClick={handlerUpdate}>Aggiorna</button>           
                    </Grid>
                </Grid>

                <Grid item
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    rowGap={'20px'}
                    >
                        {socialList.includes('facebook') && 
                            <Grid item container direction="column" gap={'20px'} xs={12} md={12} lg={(12/socialList.length)-0.05} sx={{ justifyContent: "center", alignItems: "stretch" }}>
                                <Grid item xs={12} md={12} lg={(12/socialList.length)-0.05}>{(dataGraph5 && dataGraph5.data && "string" !== typeof dataGraph5.data)?<MainIconCard element={<MixedChartComponent data={dataGraph5.data} color={'red'} type="line" />} title={dataGraph5.title} helpId={'SOCIAL_'+currentDashboardGraph[4].title} handleChange={handleChange} tooltip={helpSectionsSocial[4].description} filtersList={handleReduce(Object.entries(filtersList).filter(([key, value]) => key === 'social_pages_facebook_metric_name'))} aliasFilter={['Facebook']} icon={<FacebookIcon sx={{ fontSize: 'max(2vw, 20px)' }} />}/> : <SkeletonComponent height={'582px'}/>} </Grid>
                            </Grid>}
                        {socialList.includes('instagram') && 
                            <Grid item container direction="column" gap={'20px'} xs={12} md={12} lg={(12/socialList.length)-0.05} sx={{ justifyContent: "center", alignItems: "stretch" }}>
                                <Grid item xs={12} md={12} lg={(12/socialList.length)-0.05}>{(dataGraph6 && dataGraph6.data && "string" !== typeof dataGraph6.data)?<MainIconCard element={<MixedChartComponent data={dataGraph6.data} color={'red'} type="line"/>} title={dataGraph6.title} helpId={'SOCIAL_'+currentDashboardGraph[5].title} handleChange={handleChange} tooltip={helpSectionsSocial[5].description} filtersList={handleReduce(Object.entries(filtersList).filter(([key, value]) => key === 'social_pages_instagram_metric_name'))} aliasFilter={['Instagram']} icon={<InstagramIcon sx={{fontSize:'max(2vw, 20px)'}}/>}/> : <SkeletonComponent height={'582px'}/>} </Grid>
                            </Grid>}
                        {/* {socialList.includes('tiktok') &&
                            <Grid item container direction="column" gap={'20px'} xs={12} md={12} lg={(12/socialList.length)-0.05} sx={{ justifyContent: "center", alignItems: "stretch" }}>
                                <Grid item xs={12} md={12} lg={(12/socialList.length)-0.05}>{(dataGraph7 && dataGraph7.data && "string" !== typeof dataGraph7.data)?<MainIconCard element={<MixedChartComponent data={dataGraph7.data} color={'red'} type="line"/>} title={dataGraph7.title} helpId={'SOCIAL_'+currentDashboardGraph[6].title} handleChange={handleChange} tooltip={helpSectionsSocial[6].description}filtersList={handleReduce(Object.entries(filtersList).filter(([key, value]) => key === 'social_pages_tiktok_metric_name'))} aliasFilter={['TikTok']} icon={<TikTokIcon />}/> : <SkeletonComponent height={'582px'}/>} </Grid>
                            </Grid>} */}
                </Grid>
                
                <Grid item xs={12}><div className="overview-tab-section">{'Insights sui contenuti'}</div></Grid>

                <Grid item
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    rowGap={'20px'}
                    >
                    <Grid item xs={12}>{(dataGraph8 && dataGraph8.data && "string" !== typeof dataGraph8.data)
                        ?<MainIconCard element={<TableComponent rows={dataGraph8.data} hideColumns={GraphData['social_pages'][7].hideColumns} columns={
                            GraphData['social_pages'][7].columnTableList?.map(el => { return{field: el, headerName: el.replace(/_/g, ' '), hide: GraphData['social_pages'][7].hideColumns?.includes(el)}})
                        }/>} title={dataGraph8.title} helpId={'SOCIAL_'+currentDashboardGraph[7].title} handleChange={handleChange} tooltip={helpSectionsSocial[7].description} icon={<FacebookIcon sx={{ fontSize: 'max(2vw, 20px)' }} />} />
                        :<SkeletonComponent height={'800px'}/>}
                    </Grid>
                </Grid>

                <Grid item
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    rowGap={'20px'}
                    >
                    <Grid item xs={12}>{(dataGraph9 && dataGraph9.data && "string" !== typeof dataGraph9.data)
                        ?<MainIconCard element={<TableComponent rows={dataGraph9.data} hideColumns={GraphData['social_pages'][8].hideColumns} columns={
                            GraphData['social_pages'][8].columnTableList?.map(el => {return{field:el, headerName:el.replace(/_/g, ' ')}})
                        }/>} title={dataGraph9.title} helpId={'SOCIAL_'+currentDashboardGraph[8].title} handleChange={handleChange} tooltip={helpSectionsSocial[8].description} icon={<InstagramIcon sx={{ fontSize: 'max(2vw, 20px)' }} />} />
                        :<SkeletonComponent height={'800px'}/>}
                    </Grid>
                </Grid>

            </Grid>
        </ThemeProvider>
    )
}