import { createTheme } from "@mui/material";

export const generateColorPalette = (data, color) => {
  const startColor = (color === 'red') ? [255, 230, 230] : [204, 229, 255];  // Colore iniziale senza tonalità violacee
  const endColor = (color === 'red') ? [255, 0, 0] : [0, 128, 255]; // Mantieni colori puri di rosso o blu
  const maxValue = Math.max(...data); // Trova il valore massimo in 'data'
  const exponent = 1.5; // Aggiungi un esponente per enfatizzare il gradiente

  if (data.length > 1) {
    const colorPalette = data.map((item) => {
      const ratio = Math.pow(item / maxValue, exponent); // Applica l'esponente per enfatizzare il gradiente
      const color = startColor.map((start, i) => {
        return Math.round(start + ratio * (endColor[i] - start)); // Calcola il colore in base al rapporto amplificato
      });
      return rgbToHex(color); // Converte l'RGB in esadecimale
    });
    return colorPalette;
  } 
  return [rgbToHex(endColor)]; // Ritorna il colore finale come palette
};

export const generateColorPalettePie = (dataLabel, data, color) => {
  if (data.length > 0) {
    const startColor = (color === 'red') ? [255, 230, 230] : [204, 229, 255];  // Colore iniziale senza tonalità violacee
    const endColor = (color === 'red') ? [255, 0, 0] : [0, 128, 255]; // Mantieni colori puri di rosso o blu
    const maxSteps = Math.min(data.length, 6);
    const grey = rgbToHex([80, 80, 80])

    if (data.length > 1) {
      const palette:any = [];
      for (let i = 0; i < data.length; i++) {
        if (i === 0) {
          if (dataLabel[i].toLowerCase() === 'others') palette.push(grey);
          else palette.push(rgbToHex(endColor));

        } else if (i < maxSteps) {
          const index = maxSteps - 1 - Math.min(i, maxSteps - 1); 
          const factor = index / maxSteps;

          // Mantieni solo il componente rosso o blu, evitando il blu se il colore è rosso e viceversa
          const r = (color !== 'red') ? Math.round(startColor[0] + factor * (endColor[0] - startColor[0])) : startColor[0]; // Rosso
          const g = Math.round(startColor[1] + factor * (endColor[1] - startColor[1])); // Blu
          const b = Math.round(startColor[2] + factor * (endColor[2] - startColor[2])); // Blu

          if (dataLabel[i].toLowerCase() === 'others') palette.push(grey);
          else palette.push(rgbToHex([r, g, b]));

        } else {
          if (dataLabel[i].toLowerCase() === 'others') palette.push(grey);
          else palette.push(rgbToHex(startColor));               
        }
      }
      return palette;
    } 
    return [rgbToHex(endColor)]; // Ritorna il colore finale come palette
  }
  return []
};

// Funzione per calcolare la media dei dati
export const calculateAverage = (data) => {
    const total = data.reduce((acc, value) => acc + value, 0);
    return total / data.length;
};

// Funzione per calcolare il colore in base all'indice di media con enfatizzazione del gradiente e senza tonalità violacee
export const getColorBasedOnIndex = (index, totalIndices, color) => {
    // Aggiorna i colori per evitare tonalità violacee
    const startColor = (color === 'red') ? [255, 230, 230] : [204, 229, 255]; // Blu chiaro senza toni violacei
    const endColor = (color === 'red') ? [255, 0, 0] : [0, 128, 255]; // Rosso o Blu

    const exponent = 1.5; // Esponente per enfatizzare il gradiente

    // Controllo per evitare divisioni per zero o calcoli errati
    if (typeof index !== 'number' || typeof totalIndices !== 'number' || totalIndices <= 0) {
        return `rgb(${endColor[0]}, ${endColor[1]}, ${endColor[2]})`; // Fallback al colore iniziale
    }

    const scale = Math.pow(index / totalIndices, exponent); // Applica l'esponente per amplificare il gradiente

    const newColor = startColor.map((c, i) => Math.round(c + scale * (endColor[i] - c)));

    return `rgb(${newColor[0]}, ${newColor[1]}, ${newColor[2]})`;
};


// Funzione di supporto per convertire RGB in esadecimale
const rgbToHex = (rgb) => {
  return `#${rgb.map(c => c.toString(16).padStart(2, '0')).join('')}`;
};

export const formatNumber = (num: number, format = 'it-IT'): string => {
  try{
    return new Intl.NumberFormat(format).format(num);
  } catch(e){ return String(num) }
};

// Funzione per formattare i label dell'asse
export const formatDataLabel = (value, yType = 'data', filtroData='') => {
    try{
      switch(yType){
        case 'data': return (typeof value === 'string')? value.replace("T00:00:00", ''):value;
        case 'timestamp': return formatDateTime(value, filtroData);
        case 'fascia': return Number(value) + 1;
        default: return value;
      } 
    }catch(e){
      return value;
  }
};

export const formatDateTime = (dateString, filtroData= '') => {
  try{
    const date = new Date(dateString); // Crea un oggetto Date dalla stringa
    
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Aggiungi zero davanti se il mese è inferiore a 10
    const day = String(date.getDate()).padStart(2, '0'); // Aggiungi zero davanti se il giorno è inferiore a 10
    const hours = String(date.getHours()).padStart(2, '0'); // Aggiungi zero davanti se l'ora è inferiore a 10
    const minutes = String(date.getMinutes()).padStart(2, '0'); // Aggiungi zero davanti se i minuti sono inferiori a 10

    if( filtroData === 'Ultime 24 ore (60 min.)'){
      return `${hours}`
    }
    else if( filtroData === 'Ultima settimana (6 ore)'){
      return `${day}-${month}-${year} ${hours}`
    }
    return `${hours}:${minutes}`; // Restituisci la stringa nel formato richiesto
  }catch(e){return dateString}
}

export const formatDataGVaux = (value) => {
    if (typeof value === 'number') {
        return (value/1000000000) + ' GVaux';
    }
    return value;
};

export const formatNumLabel = (value) => {
    if (value >= 1000) {
        return `${(value / 1000).toFixed(0)} K`; // Mostra "x K" per valori >= 1000
    }
    return value; // Mostra il valore normale per valori < 1000
};

export const formatGrouppedLegend = (value, maxChar = 30) => {
    let newValue = value.replace(/[\r\n]+/g, ' ')
    if(newValue.length > maxChar) return newValue.substring(0, maxChar-3) + '...'
    return newValue
}

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    divider: '#fff',
    text:{
      primary: '#fff',
      secondary: '#fff', 
      disabled: '#000', 
    },
    
    background:{
      default: '#000',
      paper: '#000',
    }
  },
});

export const transformDataToBarChart = (pieChartData) => {
    try{
      return {
              label: "label",
              xAxis: pieChartData.data.map(item => item.name.trim()),
              yAxis: pieChartData.data.map(item => item.value),
      };
    }catch(e) {return {label:'', xAxis:[], yAxis:[]}}
};

export const transformDataToStackedChart = (pieChartData, column) => {
    try{
      const xAxisData = [column];
      const transformedData =  { 
          xAxisData: xAxisData,
          seriesData:[
          ...pieChartData.data.map(item => {return {
              name: item.name,
              data: [item.value]
          }})]
      }
      return transformedData;
    }catch(e) {return {label:'', xAxis:[], yAxis:[]}}
};

export const filterPieGraphValue = (data, searchString) => {
    try{
       if(data){
          const filteredData = data.data.filter((item, index) => {
            return item.name.toLowerCase().includes(searchString.toLowerCase()) || searchString === '';
          });
          
          return { ...data, data: filteredData } 
      };
    }catch(e) {/* console.log(e); */  return []}
};

export const filterBarGraphValue = (data, searchString) => {
    try{
       if(data){
          const { xAxis, yAxis } = data;
          const filteredIndices = xAxis.map((item, index) => {       
              return item.toLowerCase().includes(searchString.toLowerCase()) || searchString === '';
          });
          const filteredX = xAxis.filter((_, index) => filteredIndices[index]);
          const filteredY = yAxis.filter((_, index) => filteredIndices[index]);
          return { ...data, xAxis: filteredX, yAxis: filteredY } 
      };
    }catch(e) {/* console.log(e); */ return []}
};

export const  processData = (data, flag) => {
    if (!data || !Array.isArray(data.data)) return data;

    if (flag) {
        const noCategorized = data.data.find(item => item.name === "No Category");
        const summedValue = data.data
            .filter(item => item.name !== "No Category")
            .reduce((sum, item) => sum + item.value, 0);
        return { ...data, data: [ noCategorized || { name: "No Category", value: 0 }, {name: "Categorizzati",value: summedValue} ]};
    } else return { ...data, data: data.data.filter(item => item.name !== "No Category") };
}