import React from 'react';
import { Overview } from '../dashboards/Overview.tsx';
import { Audience } from '../dashboards/Audience.tsx';
import { ContenutiLive } from '../dashboards/ContenutiLive.tsx';
import { ContenutiOnDemand } from '../dashboards/ContenutiOnDemand.tsx';
import { Music } from '../dashboards/Music.tsx';
import { Advertising } from '../dashboards/Advertising.tsx';
import { Platforms } from '../dashboards/Platforms.tsx';
import { Social } from '../dashboards/Social.tsx';
import Help from '../dashboards/Help.tsx';
import Settings from '../dashboards/Settings.tsx';
import '../../style/home.css'
import UAE from '../dashboards/UAE.tsx';
import { OnAir } from '../dashboards/OnAir.tsx';

const Dashboard = ({ currentDashboardGraph, handlerUpdate, indexDashboard, idDashboard, isLargeScreen, helpId, handleChange, filtersList }) => {

    switch(idDashboard.toLowerCase()){
        case 'overview': return <Overview currentDashboardGraph={currentDashboardGraph} indexDashboard={indexDashboard} idDashboard={idDashboard} isLargeScreen={isLargeScreen} handleChange={handleChange}/>;
        case 'audience': return <Audience currentDashboardGraph={currentDashboardGraph} indexDashboard={indexDashboard} idDashboard={idDashboard} isLargeScreen={isLargeScreen} handleChange={handleChange}/>;
        case 'real_time': return <OnAir currentDashboardGraph={currentDashboardGraph} indexDashboard={indexDashboard} idDashboard={idDashboard} isLargeScreen={isLargeScreen} handleChange={handleChange}/>;
        case 'contenuti_live': return <ContenutiLive currentDashboardGraph={currentDashboardGraph} indexDashboard={indexDashboard} idDashboard={idDashboard} isLargeScreen={isLargeScreen} handleChange={handleChange}/>;
        case 'contenuti_on_demand': return <ContenutiOnDemand currentDashboardGraph={currentDashboardGraph} indexDashboard={indexDashboard} idDashboard={idDashboard} isLargeScreen={isLargeScreen} handleChange={handleChange}/>;
        case 'music': return <Music currentDashboardGraph={currentDashboardGraph} indexDashboard={indexDashboard} idDashboard={idDashboard} isLargeScreen={isLargeScreen} handleChange={handleChange}/>;
        case 'advertising': return <Advertising currentDashboardGraph={currentDashboardGraph} indexDashboard={indexDashboard} idDashboard={idDashboard} isLargeScreen={isLargeScreen} handleChange={handleChange}/>;
        case 'platforms': return <Platforms currentDashboardGraph={currentDashboardGraph} indexDashboard={indexDashboard} idDashboard={idDashboard} isLargeScreen={isLargeScreen} handleChange={handleChange}/>;
        case 'social_pages': return <Social currentDashboardGraph={currentDashboardGraph} indexDashboard={indexDashboard} idDashboard={idDashboard} isLargeScreen={isLargeScreen} filtersList={filtersList} handlerUpdate={handlerUpdate} handleChange={handleChange}/>;
        case 'uae' : return <UAE currentDashboardGraph={currentDashboardGraph} indexDashboard={indexDashboard} idDashboard={idDashboard} isLargeScreen={isLargeScreen} handleChange={handleChange}/>
        case 'settings': return <Settings />;
        case 'help': return <Help helpId={helpId}/>;
    }
}

export default React.memo(Dashboard);