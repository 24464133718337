import React from 'react';
import { Box, Grid, IconButton, Tooltip } from '@mui/material';
import SkeletonComponent from '../utils/Skeleton.tsx';
import BarChartWithLabelsComponent from '../graphs/BarChart/BarChartWithLabelsComponent.tsx';
import BarChartVerticalWithLabelsComponent from '../graphs/BarChart/BarChartVerticalWithLabelsComponent.tsx';
import axios from 'axios';
import { fecthDataGraph } from '../utils/promiseUtils.tsx';
import useAuth from '../hooks/useAuth.ts';
import MixedChartComponent from '../graphs/MixedChart/MixedChartComponent.tsx';
import MainCard from './MainCard.tsx';
import { helpSectionsSettings } from '../utils/HelpDescriptions.tsx';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const DataQuality = ( ) => {
    const [dataGraph, setDataGraph] = React.useState<any>(null);
    const [dataGraph2, setDataGraph2] = React.useState<any>(null)
    const [permits, setPermits] = React.useState<any>(null)
    const [currentDashboardGraph, setCurrentDashboardGraph] = React.useState<any>([{title:'COMPARAZIONE SESSIONI', filtersList:[], payload:{start_date:"", end_date:"", group_by: [], chart_type:"MIXER_BAR_LINE_CHART"}}])
        
    const { user, logout } = useAuth();
    const {getToken} = useAuth();

    const handleDataGraph = ( index, responseData ) => {
        switch(index){
            case 0: setDataGraph2(JSON.parse(JSON.stringify({ title: currentDashboardGraph[index].title, data: responseData.data })));break;
        }
    }

    const getLabelData = () => {
      return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_BACKEND_URL + 'get_data_quality', {} )
          .then((response) => {
            resolve({ data: response.data });
          })
          .catch((error) => {
            reject(error);
          });
      });
    };

    const getUserData = () => {
      return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_BACKEND_URL + 'users', {email:user?.email} )
          .then((response) => {
            resolve({ data: response.data });
          })
          .catch((error) => {
            reject(error);
          });
      });
    };

    function callMutationUserData() {
      getLabelData()
      .then((response: any) => {
        let userData = response.data;
        setDataGraph(userData);
      })
      .catch((error) => { console.log(error) });
    }

    React.useEffect(() => {
        callMutationUserData();
    }, []);

    React.useEffect(() => {
       fecthDataGraph(currentDashboardGraph, getToken, 'real_time', [0], handleDataGraph);

        getUserData()
        .then((response: any) => {
            try{
            let userData = response.data[0]?.session_with_token;        
            setPermits(userData)
            }catch(e) {setPermits(false)}
        })
        .catch((error) => { setPermits(false) });
    }, [currentDashboardGraph]);

    return (
        <Grid container
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            gap='40px'>

          
            <Grid item
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                rowGap={'20px'}
                >

                <Grid item xs={12}>{(dataGraph && dataGraph[0].data)
                    ?<div>
                        <div className="graph-quality-title" >
                            <span>
                                {dataGraph[0].title}
                            </span>
                        </div>
                        <BarChartWithLabelsComponent data={dataGraph[0].data} showLabel={true} perc={true}/>
                    </div>
                    :<SkeletonComponent/>}
                </Grid>
            </Grid>

            <Grid item
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                rowGap={'20px'}
                >

                <Grid item xs={12}>{(dataGraph && dataGraph[0].data)
                    ?<div>
                        <div className="graph-quality-title" >
                            <span>
                                {dataGraph[1].title}
                            </span>
                        </div>
                        <BarChartVerticalWithLabelsComponent data={dataGraph[1].data} color={'blue'} showLabel={true}/>
                    </div>
                    :<SkeletonComponent/>}
                </Grid>
                
            </Grid>

            {permits && <Grid item
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                rowGap={'20px'}
            >  
                <Grid item xs={12}>{(dataGraph2 && dataGraph2.data) 
                    ?<div>
                        <div className="graph-quality-title" >
                            <span> {'Comparazione sessioni'}</span>
                            <Tooltip title={
                                <Box style={{ fontSize: "1.2rem" }}>
                                    {helpSectionsSettings[0].description}
                                </Box >
                                } arrow >
                                <IconButton sx={{ color: 'white' }}>
                                    <HelpOutlineIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <MixedChartComponent data={dataGraph2.data} color={'red'} type="line" />
                    </div>
                    :<SkeletonComponent/>}
                </Grid>
            </Grid>}
        </Grid>
    );
};

export default DataQuality;
