import React from "react";
import { Grid, ThemeProvider } from "@mui/material";
import MainCard from "../elements/MainCard.tsx";
import PieChartComponent from '../graphs/PieChart/PieChartComponent.tsx';
import StackedBarChartComponent from "../graphs/BarChart/StackedBarChartComponent.tsx";
import BarChartWithLabelsComponent from "../graphs/BarChart/BarChartWithLabelsComponent.tsx";
import SkeletonComponent from "../utils/Skeleton.tsx";
import useAuth from "../hooks/useAuth.ts";
import { fecthDataGraph} from "../utils/promiseUtils.tsx";
import { darkTheme, filterBarGraphValue, filterPieGraphValue, transformDataToBarChart } from "../utils/graphUtils.tsx";
import { helpSectionsLive } from "../utils/HelpDescriptions.tsx";

export const ContenutiLive = ({ currentDashboardGraph, indexDashboard, idDashboard, isLargeScreen, handleChange }) => {
    const [dataGraph1, setDataGraph1] = React.useState<any>(null)
    const [dataGraph2, setDataGraph2] = React.useState<any>(null)
    const [dataGraph3, setDataGraph3] = React.useState<any>(null)
    const [dataGraph4, setDataGraph4] = React.useState<any>(null)
    const [dataGraph5, setDataGraph5] = React.useState<any>(null)
    const {getToken} = useAuth();

    const [filteredDataGraph1, setFilteredDataGraph1] = React.useState<any>(null)
    const [filterGraph1, setFilterGraph1] = React.useState('')
    const [filteredDataGraph2, setFilteredDataGraph2] = React.useState<any>(null)
    const [filterGraph2, setFilterGraph2] = React.useState('')
    const [filteredDataGraph3, setFilteredDataGraph3] = React.useState<any>(null)
    const [filterGraph3, setFilterGraph3] = React.useState('')
    const [filteredDataGraph5, setFilteredDataGraph5] = React.useState<any>(null)
    const [filterGraph5, setFilterGraph5] = React.useState('')

    const setDataGraph = ( index, responseData ) => {
        switch(index){
            case 0: setDataGraph1(JSON.parse(JSON.stringify({ title: currentDashboardGraph[index].title, data: responseData.data })));break;
            case 1: setDataGraph2(JSON.parse(JSON.stringify({ title: currentDashboardGraph[index].title, data: responseData.data })));break;
            case 2: setDataGraph3(JSON.parse(JSON.stringify({ title: currentDashboardGraph[index].title, data: responseData.data })));break;
            case 3: setDataGraph4(JSON.parse(JSON.stringify({ title: currentDashboardGraph[index].title, data: responseData.data })));break;
            case 4: setDataGraph5(JSON.parse(JSON.stringify({ title: currentDashboardGraph[index].title, data: responseData.data })));break;
        }
    }

    React.useEffect(() => {
        if(dataGraph1){ 
            let newList = filterBarGraphValue(transformDataToBarChart(dataGraph1.data), filterGraph1)
            setFilteredDataGraph1(newList)
        }
    }, [dataGraph1, filterGraph1]);

    React.useEffect(() => {
        if(dataGraph2){ 
            let newList = filterBarGraphValue(dataGraph2.data, filterGraph2)
            setFilteredDataGraph2(newList)
        }
    }, [dataGraph2, filterGraph2]);

    React.useEffect(() => {
        if(dataGraph3){ 
            let newList = filterBarGraphValue(dataGraph3.data, filterGraph3)
            setFilteredDataGraph3(newList)
        }
    }, [dataGraph3, filterGraph3]);

    React.useEffect(() => {
        if(dataGraph5){ 
            let newList = filterBarGraphValue(dataGraph5.data, filterGraph5)
            setFilteredDataGraph5(newList)
        }
    }, [dataGraph5, filterGraph5]);

    React.useEffect(() => {
        fecthDataGraph(currentDashboardGraph, getToken, idDashboard, [0,1,2,3,4], setDataGraph);
    }, [currentDashboardGraph]);

    return (
        <ThemeProvider theme={darkTheme}>
            <Grid container
                direction="column"
                justifyContent="center"
                alignItems="stretch"
                gap='20px'>
                
                 <Grid item
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                rowGap={'20px'}
                >
                    <Grid item xs={12}>{(dataGraph1 && dataGraph1.data && filteredDataGraph1 && "string" !== typeof dataGraph1.data)
                        ?<MainCard element={<PieChartComponent data={filterPieGraphValue(dataGraph1.data, filterGraph1)} isLargeScreen={isLargeScreen} type={'perc'}/>}
                            secondElement={<BarChartWithLabelsComponent data={filteredDataGraph1} splitted={true} sizeGraph={"small"}/>} filter={true} filterValue={filterGraph1} handleFilterChange={setFilterGraph1} title={dataGraph1.title} helpId={'LIVE_'+currentDashboardGraph[0].title} handleChange={handleChange} tooltip={helpSectionsLive[0].description}/>
                        :<SkeletonComponent/>}                
                    </Grid>
                </Grid>

                <Grid item
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    rowGap={'20px'}
                    >
                    <Grid item xs={12}>{(dataGraph4 && dataGraph4.data && "string" !== typeof dataGraph4.data)
                        ?<MainCard element={<StackedBarChartComponent data={dataGraph4.data} yType="data"/>} title={dataGraph4.title} helpId={'LIVE_'+currentDashboardGraph[3].title} handleChange={handleChange} tooltip={helpSectionsLive[3].description}/>
                        :<SkeletonComponent/>}
                    </Grid>
                </Grid>

                <Grid item
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    rowGap={'20px'}
                    >
                    <Grid item xs={12} md={12} lg={5.95}>{(filteredDataGraph2 && "string" !== typeof dataGraph2.data)
                        ?<MainCard element={<BarChartWithLabelsComponent data={filteredDataGraph2} color={'blue'} sizeGraph={'small'}/>} filter={true} filterValue={filterGraph2} handleFilterChange={setFilterGraph2} title={dataGraph2.title} helpId={'LIVE_'+currentDashboardGraph[1].title} handleChange={handleChange} tooltip={helpSectionsLive[1].description}/>
                        :<SkeletonComponent/>}
                    </Grid>
                    <Grid item xs={12} md={12} lg={5.95}>{(filteredDataGraph3 && "string" !== typeof dataGraph3.data)
                        ?<MainCard element={<BarChartWithLabelsComponent data={filteredDataGraph3} sizeGraph={'small'}/>} filter={true} filterValue={filterGraph3} handleFilterChange={setFilterGraph3} title={dataGraph3.title} helpId={'LIVE_'+currentDashboardGraph[2].title} handleChange={handleChange} tooltip={helpSectionsLive[2].description} />
                        :<SkeletonComponent/>}
                    </Grid>
                </Grid>                

                <Grid item
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    rowGap={'20px'}
                    >
                    <Grid item xs={12}>{(filteredDataGraph5 && "string" !== typeof dataGraph5.data)
                        ?<MainCard element={<BarChartWithLabelsComponent data={filteredDataGraph5} />} filter={true} filterValue={filterGraph5} handleFilterChange={setFilterGraph5} title={dataGraph5.title} helpId={'LIVE_'+currentDashboardGraph[4].title} handleChange={handleChange} tooltip={helpSectionsLive[4].description}/>
                        :<SkeletonComponent/>}
                    </Grid>
                </Grid> 
            </Grid>
        </ThemeProvider>
    )
}