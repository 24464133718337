import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Grid, ThemeProvider } from '@mui/material';
import SkeletonComponent from '../utils/Skeleton.tsx';
import { darkTheme } from '../utils/graphUtils.tsx';

const TableComponent = ({ rows, hideColumns, columns}) => {
    const [currentRows, setCurrentRows] = React.useState();

    const currentColumns: GridColDef<any>[] =
        columns.map(el => {return {
            field: el.field,
            headerName: el.headerName,
            editable: false,
            flex: 1,
            resizable: false,
      }
    });

    React.useEffect(() => {
        setCurrentRows(rows.map((el,index) => {return {...el, id:'table_row'+index}}));
    }, [rows]);

    return (
        <ThemeProvider theme={darkTheme}>
        <Grid container
            direction="row"
            gap={2}
            sx={{
            justifyContent: "center",
            alignItems: "stretch",
            }}>

            <Grid item
            container
            direction="row"
            sx={{
                justifyContent: "center",
                alignItems: "stretch",
                height: "stretch"
            }}
            >
            {(rows.length > 0) ? (
                <Box sx={{ height: '750px', width: '100%' }} >
                    <DataGrid
                        rows={currentRows}
                        columns={currentColumns.map(el => {return {...el, hide: hideColumns.includes(el.field)}})}
                        initialState={{
                        pagination: {
                            paginationModel: {
                            pageSize: 25,
                            },
                        },
                        }}
                        pageSizeOptions={[25, 50, 100]}
                        onProcessRowUpdateError={(e) => console.log(e)} // Handle any processing errors
                    />
                </Box>
            ) : (
                <Box sx={{ height: '750px', width: '100%' }} >
                    <span>Dati non disponibili, riprova</span>
                </Box>
                /* <SkeletonComponent height={'800px'} /> */
            )}
            </Grid>
        </Grid>
        </ThemeProvider>
    );
}
;

export default TableComponent;