import React from "react";
import { Grid } from "@mui/material";
import MainCard from "../elements/MainCard.tsx";
import BarChartWithLabelsComponent from "../graphs/BarChart/BarChartWithLabelsComponent.tsx";
import SkeletonComponent from "../utils/Skeleton.tsx";
import { fecthDataGraph} from "../utils/promiseUtils.tsx";
import useAuth from "../hooks/useAuth.ts";
import { filterBarGraphValue } from "../utils/graphUtils.tsx";
import { helpSectionsMusica } from "../utils/HelpDescriptions.tsx";

export const Music = ({ currentDashboardGraph, indexDashboard, idDashboard, isLargeScreen, handleChange }) => {
    const [dataGraph1, setDataGraph1] = React.useState<any>(null)
    const [dataGraph2, setDataGraph2] = React.useState<any>(null)
    const [dataGraph3, setDataGraph3] = React.useState<any>(null)
    const [dataGraph4, setDataGraph4] = React.useState<any>(null)
    const {getToken} = useAuth();

    const [filteredDataGraph1, setFilteredDataGraph1] = React.useState<any>(null)
    const [filterGraph1, setFilterGraph1] = React.useState('')
    const [filteredDataGraph2, setFilteredDataGraph2] = React.useState<any>(null)
    const [filterGraph2, setFilterGraph2] = React.useState('')
    const [filteredDataGraph3, setFilteredDataGraph3] = React.useState<any>(null)
    const [filterGraph3, setFilterGraph3] = React.useState('')
    const [filteredDataGraph4, setFilteredDataGraph4] = React.useState<any>(null)
    const [filterGraph4, setFilterGraph4] = React.useState('')
    
    const setDataGraph = ( index, responseData ) => {
        switch(index){
            case 0: setDataGraph1(JSON.parse(JSON.stringify({ title: currentDashboardGraph[index].title, data: responseData.data })));break;
            case 1: setDataGraph2(JSON.parse(JSON.stringify({ title: currentDashboardGraph[index].title, data: responseData.data })));break;
            case 2: setDataGraph3(JSON.parse(JSON.stringify({ title: currentDashboardGraph[index].title, data: responseData.data })));break;
            case 3: setDataGraph4(JSON.parse(JSON.stringify({ title: currentDashboardGraph[index].title, data: responseData.data })));break;
        }
    }

    React.useEffect(() => {
        if(dataGraph1){ 
            let newList = filterBarGraphValue(dataGraph1.data, filterGraph1)
            setFilteredDataGraph1(newList)
        }
    }, [dataGraph1, filterGraph1]);

    React.useEffect(() => {
        if(dataGraph2){ 
            let newList = filterBarGraphValue(dataGraph2.data, filterGraph2)
            setFilteredDataGraph2(newList)
        }
    }, [dataGraph2, filterGraph2]);

    React.useEffect(() => {
        if(dataGraph3){ 
            let newList = filterBarGraphValue(dataGraph3.data, filterGraph3)
            setFilteredDataGraph3(newList)
        }
    }, [dataGraph3, filterGraph3]);

    React.useEffect(() => {
        if(dataGraph4){ 
            let newList = filterBarGraphValue(dataGraph4.data, filterGraph4)
            setFilteredDataGraph4(newList)
        }
    }, [dataGraph4, filterGraph4]);

    React.useEffect(() => {
        fecthDataGraph(currentDashboardGraph, getToken, idDashboard, [0,1,2,3], setDataGraph);
    }, [currentDashboardGraph]);

    return (
        <Grid container
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            gap='20px'>

           <Grid item
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                rowGap={'20px'}
                >
                <Grid item xs={12} md={12} lg={5.95}>{(filteredDataGraph1 && "string" !== typeof dataGraph1.data)
                    ?<MainCard element={<BarChartWithLabelsComponent data={filteredDataGraph1} sizeGraph={'small'}/>} filter={true} filterValue={filterGraph1} handleFilterChange={setFilterGraph1} title={dataGraph1.title} helpId={'MUSICA_'+currentDashboardGraph[0].title} handleChange={handleChange} tooltip={helpSectionsMusica[0].description}/>
                    :<SkeletonComponent/>}
                </Grid>
                <Grid item xs={12} md={12} lg={5.95}>{(filteredDataGraph2 && "string" !== typeof dataGraph2.data)
                    ?<MainCard element={<BarChartWithLabelsComponent data={filteredDataGraph2} sizeGraph={'small'}/>} filter={true} filterValue={filterGraph2} handleFilterChange={setFilterGraph2} title={dataGraph2.title} helpId={'MUSICA_'+currentDashboardGraph[1].title} handleChange={handleChange} tooltip={helpSectionsMusica[1].description}/>
                    :<SkeletonComponent/>}
                </Grid>
            </Grid>

            <Grid item
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                rowGap={'20px'}
                >
                <Grid item xs={12} md={12} lg={5.95}>{(filteredDataGraph3 && "string" !== typeof dataGraph3.data)
                    ?<MainCard element={<BarChartWithLabelsComponent data={filteredDataGraph3} color={'blue'} sizeGraph={'small'}/>} filter={true} filterValue={filterGraph3} handleFilterChange={setFilterGraph3} title={dataGraph3.title} helpId={'MUSICA_'+currentDashboardGraph[2].title} handleChange={handleChange} tooltip={helpSectionsMusica[2].description}/>
                    :<SkeletonComponent/>}
                </Grid>
                <Grid item xs={12} md={12} lg={5.95}>{(filteredDataGraph4 && "string" !== typeof dataGraph4.data)
                    ?<MainCard element={<BarChartWithLabelsComponent data={filteredDataGraph4} color={'blue'} sizeGraph={'small'}/>} filter={true} filterValue={filterGraph4} handleFilterChange={setFilterGraph4} title={dataGraph4.title} helpId={'MUSICA_'+currentDashboardGraph[3].title} handleChange={handleChange} tooltip={helpSectionsMusica[3].description}/>
                    :<SkeletonComponent/>}
                </Grid>
            </Grid>
        </Grid>     
    )
}