// Help Sections Data
export const helpSectionsOverview = [
  {
    title: "HIGHLIGHTS AUDIENCE",
    description: 'Questa sezione sintetizza i dati principali ed evidenzia l\'andamento negli ultimi 90 giorni: ££'+
        'Numero di utenti totali: Utenti loggati e non loggati da tutte le piattaforme digitali;££'+
        'Numero Touchpoints: Numero complessivo di contatti lordi;££'+
        'Numero Web impressions: Impression totali generate sul web;££'+
        'Percentuale utenti loggati: Utenti loggati in rapporto al totale delle sessioni di ascolto e visualizzazioni web.'
  },
  {
    title: "HIGHLIGHTS SOCIAL",
    description: "Metriche aggregative specifiche delle piattaforme social Facebook e Instagram: ££Reach complessiva: Comprende le metriche di reach (Instagram) e page_impressions_unique (Facebook). ££Impressions complessive: Include le impression (Instagram) e page_impressions (Facebook). ££Numero di contenuti pubblicati: Totale di post, reel e video pubblicati su Facebook e Instagram."
  },
  {
    title: "AUDIENCE",
    description: "Visualizza la distribuzione dei touchpoints in base ai canali di fruizione, differenziando tra Radio e Radiovisione."
  },
  {
    title: "CANALI | PROGRAMMI",
    description: "Presenta la distribuzione dei touchpoints riferiti ai programmi live, con distinzione per canale e sorgente (Radio o Radiovisione)."
  },
  {
    title: "CANALE | SERIE | PROGRAMMA",
    description: "Rappresenta la distribuzione dei touchpoints associati ai contenuti on demand."
  },
  {
    title: "SOCIAL REACH",
    description: "Indica la distribuzione percentuale della reach tra le pagine social attive su Facebook e Instagram."
  },
  {
    title: "SOCIAL IMPRESSIONS",
    description: "Evidenzia la distribuzione percentuale delle impressions tra le diverse pagine social attive su Facebook e Instagram."
  },
  {
    title:'SOCIAL NUM. CONTENUTI PUBBLICATI',
    description: ''
  },
  {
    title: "PIATTAFORMA",
    description: "Fornisce la distribuzione dei touchpoints in base alla categoria di device utilizzati. I dati inerenti alle piattaforme vengono estratti tramite il servizio di terze parti 'UserStack'"
  },
  {title:'UTENTI UNICI', description:"Utenti unici nel giorno medio per piattaforma (ultima estrazione dati TER)"},
  {title:'FREQUENZA', description:"Frequenze nel giorno medio per piattaforma (ultima estrazione dati TER)"}, 
  {title:'CONTATTI EQUIVALENTI', description:"Contatti equivalenti nel giorno medio per piattaforma (ultima estrazione dati TER)"},
  {title:'DETTAGLIO', description:"Dettaglio ascolti dati TER per canale"}
];

export const helpSectionsAudience = [
  {
    title: "CANALI",
    description: "Visualizza la distribuzione dei touchpoints per i canali di fruizione, differenziando tra Radio e Radiovisione."
  },
  {
    title: "TOUCHPOINTS VS. UTENTI UNICI",
    description: "Visualizza la distribuzione giornaliera dei touchpoints e degli utenti unici. Notare che l’asse Y ha due scale differenti."
  },
  {
    title: "DISTRIBUZIONE CUMULATIVA TOUCHPOINTS PER FASCIA ORARIA",
    description: "Visualizza l'aggregazione oraria dei touchpoint nell'intervallo temporale selezionato, indicando l'orario in formato UTC e, tra parentesi, l'equivalente in ora italiana."
  },
  {
    title: "PIATTAFORMA CATEGORIZZATA",
    description: "Rappresenta la distribuzione in percentuale dei touchpoints rispetto alla piattaforma utilizzata per la fruizione dei contenuti."
  },
  {
    title: "PIATTAFORMA",
    description: "Compara il numero di touchpoints per device categorizzati rispetto a quelli non categorizzati."
  },
]

export const helpSectionsRealTime = [
  {
    title: "ON AIR - DIGITAL",
    description: "Indica il numero di sessioni attive dalle piattaforme digital. La granularità temporale varia in base alla durata dell'intervallo selezionato ed è suddivisa: ££ in quarti d'ora per periodi compresi tra le ultime 3 e 12 ore;££ 1 ora per le ultime 24 ore; ££ 3 ore per l'ultima settimana."
  },
]

export const helpSectionsLive = [
  {
    title: "AUDIENCE CANALI LIVE",
    description: "Rappresenta la distribuzione percentuale e assoluta dei touchpoints rispetto ai canali live (Radio e Radiovisione)."
  },
  {
    title: "DISTRIBUZIONE GIORNALIERA",
    description: "Rappresenta la distribuzione dei touchpoints su base giornaliera rispetto ai canali e alla sorgente (Radio e Radiovisione)."
  },
  {
    title: "PROGRAMMI",
    description: "Rappresenta la distribuzione dei touchpoints rispetto ai diversi programmi."
  },
  {
    title: "CANALI E PROGRAMMI",
    description: "Visualizza la distribuzione dei touchpoints rispetto ai canali e ai programmi, differenziando per sorgente Radio e Radiovisione."
  },  
  {
    title: "SPEAKERS",
    description: "Visualizza il numero di touchpoints per gruppo di speakers."
  },
]

export const helpSectionsOnDemand = [
  {
    title: "CANALE",
    description: "Rappresenta la distribuzione dei touchpoints rispetto ai canali on demand."
  },
  {
    title: "SERIE",
    description: "Rappresenta la distribuzione di touchpoints rispetto alle serie on demand."
  },
  {
    title: "CANALE | SERIE | PROGRAMMA",
    description: "Rappresenta la distribuzione di touchpoints rispetto a canali, serie e programmi on demand."
  },
  {
    title: "PROGRAMMA | TITOLO",
    description: "Rappresenta la distribuzione dei touchpoints rispetto al singolo contenuto on demand, evidenziando anche il programma (se presente)."
  },
  {
    title: "PROGRAMMA",
    description: "Rappresenta la distribuzione dei touchpoints rispetto ai programmi on demand."
  },
  {
    title: "CONTENUTO",
    description: "Rappresenta la distribuzione dei touchpoints rispetto ai contenuti on demand."
  },
]

export const helpSectionsMusica = [
  {
    title: "TOP CANZONI",
    description: "Rappresenta la distribuzione dei touchpoints rispetto alle singole canzoni trasmesse su tutti i canali."
  },
  {
    title: "TOP ARTISTI",
    description: "Rappresenta la distribuzione dei touchpoints rispetto agli artisti trasmessi su tutti i canali."
  },
  {
    title: "TOP CANZONI | PROGRAMMI",
    description: "Rappresenta la distribuzione dei touchpoints rispetto alle singole canzoni trasmesse durante i diversi programmi."
  },
  {
    title: "TOP ARTISTI | PROGRAMMI",
    description: "Rappresenta la distribuzione dei touchpoints rispetto agli artisti trasmessi durante i diversi programmi."
  },
]

export const helpSectionsADVERTISING = [
  {
    title: "CANALI",
    description: "Rappresenta la distribuzione percentuale e assoluta dei touchpoints dei contenuti commerciali per i diversi canali. L’aggregazione considera i singoli segmenti di fruizione di uno spot."
  },
  {
    title: "PROGRAMMI",
    description: "Rappresenta la distribuzione in percentuale e assoluta dei touchpoints di contenuti commerciali rispetto ai diversi programmi trasmessi. L’aggregazione considera i singoli segmenti di fruizione di uno spot."
  },
  {
    title: "ADVERTISING",
    description: "Rappresenta il numero di touchpoints per singolo spot pubblicitario."
  },
  {
    title: "FRUIZIONE ADVERTISING",
    description: "Rappresenta la distribuzione su base giornaliera di touchpoints per i contenuti pubblicitari. L’aggregazione considera i singoli segmenti di fruizione di uno spot."
  },
]

export const helpSectionsPIATTAFORME = [
  {
    title: "CATEGORIA",
    description: "Rappresenta la distribuzione in percentuale e assoluta dei touchpoints relativi alle categorie di device utilizzati per la fruizione dei contenuti."
  },
  {
    title: "AUDIENCE PER PIATTAFORMA",
    description: "Rappresenta la distribuzione su base giornaliera dei touchpoints per le diverse categorie di dispositivi."
  },
  {
    title: "SISTEMI OPERATIVI",
    description: "Rappresenta la distribuzione di touchpoints per sistemi operativi utilizzati dagli utenti durante la fruizione di contenuti."
  },
  {
    title: "VERSIONE SISTEMI OPERATIVI",
    description: "Rappresenta la distribuzione di touchpoints per versione di sistema operativo utilizzato dagli utenti durante la fruizione di contenuti."
  },
]

export const helpSectionsSocial = [
  {
    title: "HIGHLIGHTS SOCIAL",
    description: "Metriche aggregative specifiche delle piattaforme social Facebook e Instagram: ££Reach complessiva: Comprende le metriche di reach (Instagram) e page_impressions_unique (Facebook). ££Impressions complessive: Include le impression (Instagram) e page_impressions (Facebook). ££Numero di contenuti pubblicati: Totale di post, reel e video pubblicati su Facebook e Instagram."
  },
  {
    title: "SOCIAL REACH",
    description: "Indica la distribuzione percentuale della reach tra le pagine social attive su Facebook e Instagram."
  },
  {
    title: "SOCIAL IMPRESSIONS",
    description: "Evidenzia la distribuzione percentuale delle impressions tra le diverse pagine social attive su Facebook e Instagram."
  },
  {
    title:'SOCIAL NUM. CONTENUTI PUBBLICATI',
    description:" "
  },
  {
    title: "FACEBOOK",
    description: "Rappresenta l’andamento su base giornaliera della metrica (tra quelle disponibili per Facebook) e sul periodo selezionato."
  },
  {
    title: "INSTAGRAM",
    description: "Rappresenta l’andamento su base giornaliera della metrica (tra quelle disponibili per Instagram) e sul periodo selezionato."
  },
  {
    title: "TIKTOK",
    description: ""
  },
  {
    title: "POST FACEBOOK PER UTENTI RAGGIUNTI",
    description: "Rappresenta il valore massimo raggiunto dalle diverse metriche per i post pubblicati su Facebook."
  },
  {
    title: "POST E REEL INSTAGRAM PER UTENTI RAGGIUNTI",
    description: "Rappresenta il valore massimo raggiunto dalle diverse metriche per i contenuti pubblicati su Instagram."
  },
]

export const helpSectionsUAE = [
  {
    title: "DAY",
    description: "Rappresenta il valore in GVaux della distribuzione giornaliera dell’Unità di Ascolto Equivalente (UAE) e la distribuzione percentuale dei contatti lordi tra le sorgenti Radio, TV e Digital.\nIl calcolo avviene considerando i dati editoriali e di mercato impostati nei settings di piattaforma.\nL’UAE considera per la Radio e la TV il valore dei contatti sul giorno medio fornito dai dati TER, e per “Digital” il numero di contatti lordi (touchpoints) su base giornaliera presenti in piattaforma."
  },
  {
    title: "MONTH",
    description: "Rappresenta il valore in GVaux della distribuzione mensile dell’Unità di Ascolto Equivalente (UAE) e la distribuzione percentuale dei contatti lordi tra le sorgenti Radio, TV e Digital."
  }
];

export const helpSectionsSettings = [
  {
    title: "COMPARAZIONE SESSIONI",
    description: "Comparazione del numero di sessioni calcolate secondo la logica adottata da RTL rispetto al token di sessione di Mainstreaming."
  }
]