import React from "react";
import { Grid, IconButton, Menu, MenuItem, Select, Tab, Tabs, useMediaQuery } from "@mui/material";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import '../../style/graph.css';
import MenuIcon from '@mui/icons-material/Menu';
import useAuth from "../hooks/useAuth.ts";
import { CustomBreakPoint } from "../utils/CustomBreakPoint.tsx";

export default function Footer ({entityList, value, handleChange, a11yProps}) {

    const { user, logout } = useAuth();
    const regexString = /( |\.|@)/g  // This what i have figured but its not working :(
    const [anchorEl, setAnchorEl] = React.useState<any>(null);
    const isLargeScreen = useMediaQuery(CustomBreakPoint.breakpoints.down('mlg')); 

    const handleLogout = async () => {
        // Implement your logout logic here
        logout();
    };

    const scrollToTop = (e) => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Animazione smooth
        });
    };

     const handleMenuClick = (action) => {
        setAnchorEl(null);
        handleChange(null, action)
    };

    return (
         <footer className="footer-container">
             <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Grid container item xs={4} md={2} justifyContent='flex-start' alignItems='center'><img src={`${process.env.PUBLIC_URL}/images/kebula-inline-white-full-r.svg`} alt="logo" className="footer-image" /></Grid>
                
                <Grid container item xs={7} md={9} justifyContent='space-between' alignItems='flex-end'>
               
                    <Grid container item xs={(isLargeScreen)?12:11} justifyContent='flex-end' alignItems='flex-end'>
                        {(isLargeScreen) ? (
                            // Mostra menu a tendina quando lo schermo è piccolo
                            entityList && entityList.length > 0 && 
                                <>
                                    <IconButton
                                        aria-label="menu"
                                        onClick={(event) => setAnchorEl(event.currentTarget)}
                                        sx={{ color: 'white'}}
                                    >
                                        <MenuIcon/>
                                    </IconButton>
                                    
                                    <Menu
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl)}
                                        onClose={() => setAnchorEl(null)}
                                        MenuListProps={{
                                            'aria-labelledby': 'menu-button',
                                        }}
                                        PaperProps={{
                                            sx: {
                                                color: 'white',
                                                backgroundColor: 'var(--background-filter)',
                                            },
                                        }}
                                    >
                                    {entityList && entityList.map((tabElement, index) => (
                                        <MenuItem key={'MenuItem' + index} value={index} onClick={() => handleMenuClick(index)}>
                                            {tabElement.label}
                                        </MenuItem>
                                    ))}
                                    <MenuItem onClick={() => handleLogout()}>Esci</MenuItem>
                                </Menu> 
                            </>                        
                            ) : (
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    aria-label="basic tabs example"
                                    TabIndicatorProps={{ style: { overflowY: 'hidden', backgroundColor: 'red' } }}
                                    TabScrollButtonProps={{ sx: { color: 'white' } }}
                                >
                                    {entityList.map((tabElement, index) => (
                                        <Tab
                                            key={'Tab' + index}
                                            label={tabElement.label}
                                            {...a11yProps(index)}
                                            sx={{
                                                textTransform: 'none',
                                                fontFamily: 'Custom_font',
                                                color: 'white', // Colore del testo delle schede
                                                '&.Mui-selected': { color: 'white' }, // Colore del testo quando la scheda è selezionata
                                                display: ['Impostazioni', 'Aiuto'].includes(tabElement.label) ? 'none' : 'flex', // Nascondi le schede non desiderate
                                            }}
                                            className="tab-title"
                                        />
                                    ))}
                                </Tabs>

                            )
                        }
                        
                    </Grid>

                    {!isLargeScreen &&
                        <Grid container item xs={0.8} sx={{ paddingBottom:(!isLargeScreen)?'4px !important': '0',justifyContent:'flex-end', alignItems:'center'}}>
                            <>
                                <IconButton
                                    aria-label="menu"
                                    onClick={(event) => setAnchorEl(event.currentTarget)}
                                    sx={{ color: 'white'}}
                                >
                                    <MenuIcon/>
                                </IconButton>
                                
                                <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={() => setAnchorEl(null)}
                                    MenuListProps={{
                                        'aria-labelledby': 'menu-button',
                                    }}
                                    PaperProps={{
                                        sx: {
                                            color: 'white',
                                            backgroundColor: 'var(--background-filter)',
                                        },
                                    }}
                                >
                                    {entityList.find(el => el.label === 'Impostazioni') && <MenuItem onClick={() => handleMenuClick(entityList.findIndex(el => el.label === 'Impostazioni'))}>Impostazioni</MenuItem>}
                                    {entityList.find(el => el.label === 'Aiuto') && <MenuItem onClick={() => handleMenuClick(entityList.findIndex(el => el.label === 'Aiuto'))}>Aiuto</MenuItem>}
                                    <MenuItem onClick={() => handleLogout()}>Esci</MenuItem>
                                </Menu>
                            </>
                    </Grid>
                }
                </Grid>

                <Grid container item xs={1} justifyContent='end' alignItems='center' >
                    <ArrowUpwardIcon className="back-to-top-button" onClick={scrollToTop}/>
                </Grid>
            </Grid>
          </footer>
    )
}   