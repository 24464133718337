import React from "react";
import { Card, CardContent, Tooltip, IconButton, Grid, Typography, Box, useMediaQuery, useTheme } from "@mui/material";
import '../../style/graph.css';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import TextFilterComponent from "./TextFilterComponent.tsx";

interface MainIconCardDef {
    element: any, secondElement?: any, resize?: number, title?: string, helpId?: string, handleChange?: any, tooltip?: any, filter?: boolean, filterValue?: string, handleFilterChange?: any, nota?: string, switchTitle?: boolean
}

const MainCard: React.FC<MainIconCardDef> = ({ element, secondElement=undefined, resize=7, title='', helpId='', handleChange = (value) => {}, tooltip= '', filter=false, filterValue = '', handleFilterChange = (value) => {}, nota = '', switchTitle = false}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <Card style={{ borderColor: 'transparent !important', borderRadius: '15px' }}>
            <CardContent>
                <Grid container direction="row" sx={{ justifyContent: "flex-start", alignItems: "center", paddingBottom:((title !== '')?'10px':'0px')}} >
                    <Grid 
                        item 
                        xs={(filter)?8:12} 
                        container 
                        direction="row" 
                        alignItems="center" 
                        justifyContent="flex-start"
                        sx={{ gap: 1 }}
                        >
                        {title && (
                            <span className="graph-title" style={{ color: 'white' }}>
                            {(switchTitle)?nota:title}
                            </span>
                        )}
                        {tooltip && (
                            <Tooltip title={
                                <Box style={{ fontSize: "1.2rem" }}>
                                    {tooltip.includes('££') ? 
                                        <Box>
                                            {tooltip.split('££')[0]}
                                            <ul style={{ paddingLeft: '20px' }}>
                                                {tooltip.split('££').slice(1).map((item, index) => (
                                                    <li key={index}>{item}</li>
                                                ))}
                                            </ul>
                                        </Box>
                                    : tooltip
                                } </Box >} arrow >
                                <IconButton sx={{ color: 'white' }} onClick={() => handleChange(helpId)}>
                                    <HelpOutlineIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Grid>                    

                    {filter && <Grid 
                            item 
                            xs={4} 
                            container 
                            direction="row" 
                            justifyContent="flex-end" 
                            alignItems="center"
                        >
                        <Box sx={{ maxWidth: '250px', width: '100%' }}>
                            <TextFilterComponent 
                                filterValue={filterValue} 
                                handleFilterChange={handleFilterChange}                                
                            />
                        </Box>
                    </Grid>}

                    {nota !== '' && <Grid 
                        item 
                        xs={12} 
                        container 
                        direction="row" 
                        alignItems="center" 
                        justifyContent="flex-start"
                        sx={{ gap: 1 }}
                        >
                        <span style={{ color: 'white' }}>{(switchTitle)?title:nota}</span>
                    </Grid>}
                </Grid>

                <Grid container direction="row" sx={{ justifyContent: "center", alignItems: "center" }} >
                    <Grid item xs={(secondElement && isMobile)?resize:12}>
                        {element}
                    </Grid>
                    {secondElement && isMobile && <Grid item xs={12-resize}>
                        {secondElement}
                    </Grid>}
                </Grid>
            </CardContent>
        </Card>
    );
}

export default MainCard;